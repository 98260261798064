.trainings-table {
  width: 100%;

  thead th {
    font-weight: $font-weight-bold;
    text-align: left;
  }

  tbody {
    tr {
      cursor: pointer;
      position: relative;
      padding-bottom: 0.5em;

      &:not(:last-child)::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: 1px solid color(--primary);
        opacity: 0.13;

        .l-dark & {
          border-bottom-color: color(--white);
        }
      }
    }

    td {
      font-family: $font-family-narrow;
      padding: 0.75rem 0.75rem 0 0.75rem;

      @include media-breakpoint-up(md) {
        padding: 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 1.25rem;
      }

      &:first-child {
        @include media-breakpoint-up(md) {
          padding-left: 0;
        }
      }

      &:last-child {
        padding-bottom: 0.75em;

        @include media-breakpoint-up(md) {
          padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
          padding-bottom: 1.25rem;
        }
      }

      .icon {
        font-size: 0.7em;
        margin-right: 1em;
      }
    }

    .table-cell {
      &-dates {
        width: 6em;
        font-weight: $font-weight-bold;
        opacity: 0.5;
        transition: opacity 0.35s ease;

        @include media-breakpoint-up(lg) {
          width: auto;
          font-size: 1.2em;
        }
      }

      &-title {
        font-family: $font-family-base;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(lg) {
          font-size: 1.4em;
        }

        a {
          display: block;
          position: relative;

          @include media-breakpoint-up(lg) {
            margin-left: 2em;

            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              left: -2em;
              width: 1rem;
              height: 2px;
              background-image: linear-gradient(
                to right,
                color(--white) 0%,
                color(--white) 51%,
                var(--current-color) 51%,
                var(--current-color) 100%
              );
              background-size: 200%;
              background-position: left center;
              transition: background-position 0.35s ease;

              .l-dark & {
                background-image: linear-gradient(
                  to right,
                  color(--primary) 0%,
                  color(--primary) 51%,
                  var(--current-color) 51%,
                  var(--current-color) 100%
                );
              }
            }
          }
        }
      }

      &-price {
        text-align: right;
      }

      &-seats {
        color: var(--current-color);
        text-align: right;

        span {
          font-weight: $font-weight-bold;
        }
      }
    }

    tr:hover {
      .table-cell {
        &-dates {
          opacity: 1;
        }

        &-title {
          a {
            color: var(--current-color);

            @include media-breakpoint-up(lg) {
              &::before {
                background-position: right center;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;

    thead,
    thead tr,
    thead th,
    thead td,
    tbody,
    tbody tr,
    tbody th {
      display: block;
    }

    tbody {
      tr {
        display: flex;
        flex-wrap: wrap;
      }

      td {
        padding-left: 0.25em;
        padding-right: 0.25em;
      }

      .table-cell {
        &-dates {
          order: 1;
          flex-grow: 1;
          width: 66.6666%;
        }

        &-title {
          order: 3;
          width: 100%;
        }

        &-duration {
          order: 2;
          width: 33.3333%;
          text-align: right;
        }

        &-promoter {
          order: 4;
          width: 100%;
        }

        &-location {
          order: 5;
          width: 50%;
        }

        &-price {
          order: 6;
          width: 20%;
        }

        &-seats {
          order: 7;
          width: 30%;
        }
      }
    }
  }

  &-no-result {
    font-size: 1.1em;
    font-weight: $font-weight-bold;
  }
}
