.form {
  input,
  select,
  textarea {
    padding: 0.5em 1em 0.5em 0.5em;
    border: none;
    border-radius: 0;
    outline: 1px dotted transparent;
    box-shadow: none;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    transition: color 0.2s ease-out, outline 0.2s ease-out;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
  }
}
