.header-nav-primary {
  .button.menu-button {
    position: relative;
    padding: 0.5em 1.75em 0.5em 1em;
    margin-right: -0.375em;

    @include media-breakpoint-up(md) {
      padding-right: 3.25em;

      .header.is-scrolled & {
        padding-right: 1.75em;
      }
    }

    &:hover,
    &:focus {
      outline: 1px dotted color(--secondary);
      color: color(--secondary);
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 0.25em;
      transform: translateY(-0.125em);
      font-size: 1.75em;
    }

    @mixin header-nav-primary-button-label-hidden {
      display: inline-block;
      clip-path: inset(100% 0 0 100%);
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
    }

    @mixin header-nav-primary-button-label-visible {
      display: inline;
      clip-path: unset;
      width: auto;
      height: auto;
      margin: 0;
    }

    .button-label {
      @include header-nav-primary-button-label-hidden;

      @include media-breakpoint-up(md) {
        @include header-nav-primary-button-label-visible;

        .header.is-scrolled & {
          @include header-nav-primary-button-label-hidden;
        }
      }
    }
  }

  .nav-overlay {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    color: color(--white);
    background-color: color(--primary);

    @media (orientation: landscape) {
      padding: 0 calc(var(--menu-padding) * 2);
    }

    @media (orientation: portrait) {
      flex-direction: column;
    }

    @media (prefers-reduced-motion: no-preference) {
      animation: appear 0.25s ease-in 0s alternate 1 backwards;
    }

    &-primary {
      @keyframes header-nav-primary-overlay-primary {
        0% {
          transform: translateX(-1.5em);
          opacity: 0;
        }

        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

      font-weight: $font-weight-black;
      font-size: 1.25em;
      line-height: $line-height-sm;

      @media (orientation: landscape) {
        width: calc(55% - 1px);
        padding-right: calc(var(--menu-padding) / 2);
        border-right: 1px solid color(--white);

        @include media-breakpoint-up(xs) {
          width: calc(60% - 1px);
        }

        @include media-breakpoint-up(md) {
          width: calc(75% - 1px);
          font-size: 1.5em;
        }

        @include media-breakpoint-up(lg) {
          font-size: 2em;
        }

        @include media-breakpoint-up(lg, 'height') {
          @include media-breakpoint-up(xl) {
            font-size: 2.5em;
          }
        }

        @include media-breakpoint-up(xl, 'height') {
          @include media-breakpoint-up(xxl) {
            font-size: 3em;
          }
        }
      }

      @media (orientation: portrait) {
        padding-top: 10vh;
        text-align: center;

        @include media-breakpoint-up(lg, 'height') {
          padding-top: 15vh;
        }
      }

      .menu li[role='menuitem'] {
        margin-bottom: 0.25em;
        outline: none;

        @include media-breakpoint-up(xxs, 'height') {
          margin-bottom: 0.5em;
        }

        @media (orientation: portrait) {
          @include media-breakpoint-up(lg, 'height') {
            margin: 2vh 0;
            font-size: 1.4em;
          }
        }

        @media (prefers-reduced-motion: no-preference) {
          &:nth-child(1) {
            animation: header-nav-primary-overlay-primary 0.25s ease-in 0.25s alternate 1 backwards;
          }

          &:nth-child(2) {
            animation: header-nav-primary-overlay-primary 0.25s ease-in 0.3s alternate 1 backwards;
          }

          &:nth-child(3) {
            animation: header-nav-primary-overlay-primary 0.25s ease-in 0.35s alternate 1 backwards;
          }

          &:nth-child(4) {
            animation: header-nav-primary-overlay-primary 0.25s ease-in 0.4s alternate 1 backwards;
          }

          &:nth-child(5) {
            animation: header-nav-primary-overlay-primary 0.25s ease-in 0.45s alternate 1 backwards;
          }

          &:nth-child(6) {
            animation: header-nav-primary-overlay-primary 0.25s ease-in 0.5s alternate 1 backwards;
          }
        }

        &:hover,
        &:focus {
          color: color(--secondary);
        }
      }
    }

    &-secondary {
      @keyframes header-nav-primary-overlay-secondary {
        0% {
          transform: translateX(3em);
          opacity: 0;
        }

        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

      padding-left: var(--menu-padding);
      font-family: $font-family-narrow;
      font-weight: $font-weight-medium;

      @media (orientation: landscape) {
        width: 45%;

        @include media-breakpoint-up(xs) {
          width: 40%;
        }

        @include media-breakpoint-up(md) {
          width: 25%;
        }
      }

      @media (orientation: portrait) {
        padding-top: 5vh;

        @include media-breakpoint-up(lg, 'height') {
          padding-top: 10vh;
        }
      }

      .menu-contact {
        text-transform: uppercase;

        @media (prefers-reduced-motion: no-preference) {
          animation: header-nav-primary-overlay-secondary 0.25s ease-in 0.65s alternate 1 backwards;
        }

        .icon.l-round {
          margin-right: 1em;
          background-color: color(--white);
          color: color(--primary);

          @include media-breakpoint-up(lg) {
            width: 3em;
            height: 3em;
            line-height: 3em;
          }
        }

        a {
          color: color(--white);
        }

        &:hover,
        &:focus {
          .icon.l-round {
            background-color: color(--secondary);
          }

          a {
            color: color(--secondary);
          }
        }
      }

      .form {
        @media (prefers-reduced-motion: no-preference) {
          animation: header-nav-primary-overlay-secondary 0.25s ease-in 0.7s alternate 1 backwards;
        }

        &-newsletter {
          /**
           * @see _form-newsletter.scss
           */
        }
      }

      .menu {
        display: flex;
        // justify-content: space-between;
        width: 100%;

        @media (prefers-reduced-motion: no-preference) {
          animation: header-nav-primary-overlay-secondary 0.25s ease-in 0.75s alternate 1 backwards;
        }

        li[role='menuitem'] {
          outline: none;

          &:not(:last-child) {
            margin-right: 0.5em;
          }

          .icon.l-round {
            background-color: color(--secondary);
            color: color(--primary);

            &:hover,
            &:focus {
              background-color: color(--white);
            }
          }
        }
      }
    }

    .button.close-button {
      @keyframes header-nav-primary-overlay-close-button {
        0% {
          transform: translate(10em, -10em);
        }

        100% {
          transform: translate(50%, -50%);
        }
      }

      position: absolute;
      top: 0.5em;
      right: 0.5em;
      width: calc(var(--menu-padding) * 2);
      min-width: 4.5em;
      height: calc(var(--menu-padding) * 2);
      min-height: 4.5em;
      transform: translate(50%, -50%);
      font-size: 1.5em;

      @include media-breakpoint-up(md) {
        top: calc(var(--menu-padding) / 2);
        right: calc(var(--menu-padding) / 2);
      }

      @media (prefers-reduced-motion: no-preference) {
        animation: header-nav-primary-overlay-close-button 0.5s ease-in 1s alternate 1 backwards;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: calc(var(--menu-padding) * 2);
        min-width: 4.5em;
        height: calc(var(--menu-padding) * 2);
        min-height: 4.5em;
        border-radius: 100%;
        background-color: color(--white);
        opacity: 0.15;
      }

      .icon {
        display: inline-block;
        transform: translate(-50%, 50%);
      }
    }

    &.is-closing {
      @media (prefers-reduced-motion: no-preference) {
        .button.close-button {
          transform: translate(10em, -10em);
          transition: transform 0.25s ease-out;
        }

        .nav-overlay-secondary {
          .menu {
            opacity: 0;
            transform: translateX(3em);
            transition: transform 0.25s ease-out 0.15s, opacity 0.25s ease-out 0.15s;
          }

          .form {
            opacity: 0;
            transform: translateX(3em);
            transition: transform 0.25s ease-out 0.2s, opacity 0.25s ease-out 0.2s;
          }

          .menu-contact {
            opacity: 0;
            transform: translateX(3em);
            transition: transform 0.25s ease-out 0.25s, opacity 0.25s ease-out 0.25s;
          }
        }

        .nav-overlay-primary .menu li[role='menuitem'] {
          &:nth-child(6) {
            opacity: 0;
            transform: translateX(-1.5em);
            transition: transform 0.25s ease-out 0.5s, opacity 0.25s ease-out 0.5s;
          }

          &:nth-child(5) {
            opacity: 0;
            transform: translateX(-1.5em);
            transition: transform 0.25s ease-out 0.55s, opacity 0.25s ease-out 0.55s;
          }

          &:nth-child(4) {
            opacity: 0;
            transform: translateX(-1.5em);
            transition: transform 0.25s ease-out 0.6s, opacity 0.25s ease-out 0.6s;
          }

          &:nth-child(3) {
            opacity: 0;
            transform: translateX(-1.5em);
            transition: transform 0.25s ease-out 0.65s, opacity 0.25s ease-out 0.65s;
          }

          &:nth-child(2) {
            opacity: 0;
            transform: translateX(-1.5em);
            transition: transform 0.25s ease-out 0.7s, opacity 0.25s ease-out 0.7s;
          }

          &:nth-child(1) {
            opacity: 0;
            transform: translateX(-1.5em);
            transition: transform 0.25s ease-out 0.75s, opacity 0.25s ease-out 0.75s;
          }
        }

        opacity: 0;

        /**
         * The total transition duration (1.1s) should match the useCloseWithTransition `delay` parameter in NavPrimary.jsx
         */
        transition: opacity 0.25s ease-out 0.85s;
      }
    }
  }
}
