@font-face {
  font-family: 'icomoon'; /* stylelint-disable-line */
  font-weight: normal;
  font-style: normal;
  src: url('icomoon.eot?men1');

  /* prettier-ignore */
  src:
    url('icomoon.eot?men1#iefix') format('embedded-opentype'),
    url('icomoon.woff2?men1') format('woff2'),
    url('icomoon.ttf?men1') format('truetype'),
    url('icomoon.woff?men1') format('woff'),
    url('icomoon.svg?men1#icomoon') format('svg');
}
