.section.organizations {
  .article.organizations-article {
    margin: 2em 0;

    .form-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .half-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 400px;
      align-items: flex-start;
      justify-content: flex-start;

      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    @include media-breakpoint-up(xs) {
      display: flex;
      align-items: flex-start;
      margin: var(--container-padding-lg) 0;
    }

    &:first-child {
      margin-top: calc(var(--container-padding-lg) / 2);
    }

    &:last-child {
      margin-bottom: calc(var(--container-padding-lg) / 2);
    }

    .article {
      &-header {
        padding: 0 var(--container-padding-lg);

        @include media-breakpoint-up(xs) {
          width: 50%;
        }
      }

      &-content {
        padding: 2em var(--container-padding-lg);

        @include media-breakpoint-down(xs) {
          border-bottom: 1px solid color(primary);
        }

        @include media-breakpoint-up(xs) {
          width: 50%;
          padding: 0 var(--container-padding-lg);
          border-left: 1px solid color(primary);
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
        }

        &-item {
          margin-top: 1.25em;
        }

        li:first-child .article-content-item:first-child {
          margin-top: 0;
        }

        p,
        .u-url {
          font-family: $font-family-narrow;
        }

        .p-additional-name {
          font-weight: $font-weight-normal;
        }

        .p-locality + br,
        .p-country-name {
          @include visually-hidden;
        }
      }

      &-logo {
        img {
          @include media-breakpoint-down(xs) {
            max-width: 10em;
            max-height: 3em;
          }

          @include media-breakpoint-up(xl) {
            margin: 0 1em;
          }
        }

        span {
          display: block;
          margin-top: 1.5em;
          font-size: 1rem;
        }
      }

      &-address,
      &-other {
        h4 {
          color: color(secondary);
        }
      }

      &-address + .article-contacts {
        margin-top: 0;
      }
    }
  }
}
