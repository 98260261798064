.header-form-newsletter {
  display: flex;
  align-items: flex-end;

  @media (orientation: landscape) {
    margin: 10vh 0;

    @include media-breakpoint-up(lg) {
      @include media-breakpoint-up(xl, 'height') {
        margin: 25vh 0;
      }
    }
  }

  @media (orientation: portrait) {
    margin: 5vh 0;
  }

  .form-field {
    label {
      color: color(--secondary);
    }

    &-control {
      input {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid color(--white);
        background-color: transparent;
        font-weight: $font-weight-normal;

        &:placeholder-shown::placeholder {
          opacity: 1;
        }
      }
    }

    &-error {
      color: color(--red);
      font-weight: $font-weight-normal;
    }

    &-description {
      margin-top: 0.5em;
      font-size: 0.9em;
      font-weight: $font-weight-normal;
    }
  }

  .form-confirmation {
    font-weight: $font-weight-normal;
  }

  .button.submit-button {
    margin-left: 1em;
  }
}
