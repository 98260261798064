.filter {
  .button.overlay-button {
    position: relative;
    z-index: 1;
    font-weight: $font-weight-normal;
    border: 1px solid rgba(var(--white-r), var(--white-g), var(--white-b), 0.3);

    @include media-breakpoint-up(xl) {
      white-space: nowrap;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: var(--white);
      opacity: 0.12;
    }
  }

  &-overlay {
    position: absolute;
    z-index: 1;
    padding: 0.25em 0 0.5em 0.5em;
    background-color: color(--white);
    border: none;

    @include media-breakpoint-up(md) {
      min-width: max-content;
    }

    @media (prefers-reduced-motion: no-preference) {
      animation: appear 0.25s ease-in 0s alternate 1 backwards;

      &.is-closing {
        opacity: 0;
        transition: opacity 0.25s ease-out;
      }
    }

    .l-dark & {
      background-color: color(--primary);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border: 1px solid var(--current-color);
      opacity: 0.12;

      .l-dark & {
        border-color: color(--white);
      }
    }

    label:not(.is-disabled):hover,
    label:not(.is-disabled):focus {
      cursor: pointer;
      color: color(--secondary);
    }

    .button.close-button {
      position: sticky;
      top: 0;
      right: 0;
      z-index: 1;
      padding: 0.75em;
      float: right;
      line-height: 0.5em;
      transform: translate(0.25em, -0.5em);

      .icon {
        font-size: 0.5em;
      }
    }

    .checkbox-group,
    .radio-group {
      min-width: 6em;
      padding-right: 1.5em;
    }
  }

  &-content {
    max-height: 10em;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-color: rgba(color(--primary-r), color(--primary-g), color(--primary-b), 0.5) color(--grey-light);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: rgba(color(--black-r), color(--black-g), color(--black-b), 0.5);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(color(--white-r), color(--white-g), color(--white-b), 0.5);
    }
  }

  &-selected {
    display: inline-block;
    vertical-align: top;
    max-width: calc(100% - 0.375em);
    margin: -0.375em 0 -0.375em -0.5em;
    text-align: left;
    white-space: normal;

    @include media-breakpoint-up(xl) {
      max-width: calc(100% - 0.25em);
    }

    & + .icon {
      // margin-top: 1em;
      // float: right;
    }

    &-item {
      display: inline-block;
      padding: 0.25em 0.5em 0.25em 0;
      margin: 0.25em 0;
      background-color: color(--primary);
      border-radius: 6px;
      color: color(--white);
      font-size: 0.9em;

      &:not(:last-child) {
        margin-right: 0.5em;
      }

      .l-dark & {
        background-color: color(--white);
        color: color(--primary);
      }

      .button {
        display: flex;
        align-items: center;

        .icon {
          transform: translateY(0.125em);
        }
      }

      .icon {
        margin-right: 1em;
      }
    }
  }
}
