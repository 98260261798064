.template-single-training {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 5px;
      width: 600px;
      max-width: 90%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: relative;
    }

    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      transform: none;
      padding: 0;
      margin: 0;
      color: white;
      background-color: var(--primary);
      margin-top: -1px;
    }

    h2 {
      margin-top: 1rem;
      padding: 0 4rem;
    }

    p {
      margin: 10px 0;
      color: #555;
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 2rem 4rem;
    }

    label {
      margin-bottom: 10px;
      font-size: 0.75rem;
      font-weight: 500;
    }

    input {
      width: 100%;
      padding: 10px;
      margin-top: 5px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
    }

    button {
      padding: 10px;
      background: #fff;
      color: var(--primary);
      cursor: pointer;
      border: 1px solid var(--primary);
      width: 50%;
      border-radius: unset;
      transform: translateX(100%);
      margin-top: 1rem;
      transition: backgroundcolor 0.3s, color 0.3s, transform 0.3s;

      &:disabled {
        background: #999;
      }

      &:hover {
        background: var(--primary);
        color: #fff;
      }
    }

    .error {
      color: red;
      margin-top: 10px;
    }

    .success {
      color: green;
      margin-top: 10px;
    }
  }
}
