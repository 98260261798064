.template-formal-adult-education {
  .section.flexible {
    padding-bottom: 0;

    .flexible-content {
      &:nth-child(3),
      &:nth-child(4) {
        background-color: rgba(var(--primary-r), var(--primary-g), var(--primary-b), 0.06);
      }

      &:nth-child(3) {
        padding-top: 6em;
        margin-top: 4em;
      }

      &:nth-child(4) {
        padding-bottom: 6em;
      }

      .overlay-background::before {
        background-color: rgba(var(--black-r), var(--black-g), var(--black-b), 0.2);
        background-image: linear-gradient(
            to top,
            rgba(var(--secondary-r), var(--secondary-g), var(--secondary-b), 0.85) -15%,
            rgba(var(--secondary-r), var(--secondary-g), var(--secondary-b), 0) 70%
          ),
          linear-gradient(
            to top,
            rgba(var(--black-r), var(--black-g), var(--black-b), 0.85) -15%,
            rgba(var(--black-r), var(--black-g), var(--black-b), 0) 70%
          );
      }
    }
  }
}
