.section.trainings {
  overflow: hidden;
  z-index: 1;

  .section {
    &-title {
      padding-left: 0.5em;
      border-left: 5px solid color(--secondary);
      font-size: 2.25em;

      @include media-breakpoint-up(xs) {
        font-size: 3em;
      }

      span {
        display: block;
      }
    }

    &-content {
      /* display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: calc(100% + 1em);
      margin: 2em -0.5em 0; */
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
      width: 100%;
      height: 100%;
      margin-top: 2em;

      @include media-breakpoint-up(sm) {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, 1fr);
      }

      @include media-breakpoint-up(lg) {
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(4, 1fr);
      }

      &-article {
        margin: 0;
        position: relative;
        z-index: 1;
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        background-color: color(--grey);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transition: background-size 0.35s ease;

        &::before {
          content: '';
          float: left;
          padding-top: 75%;
          background-color: color(--black);

          @include media-breakpoint-up(xs) {
            padding-top: 140%;
          }

          @include media-breakpoint-up(sm) {
            padding-top: 100%;
          }

          @include media-breakpoint-up(md) {
            padding-top: 140%;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background: rgba(var(--black-r), var(--black-g), var(--black-b), 0.16)
            linear-gradient(
              to bottom,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0) 33%,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 1) 100%
            );
          opacity: 0.95;
          transition: background-color 0.35s ease;

          /** Not collapsed for mobile */
          @include media-breakpoint-down(lg) {
            background-color: rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0.75);
          }
        }

        &:hover {
          background-size: 110% auto;
          transition: background-size 0.35s ease 0.15s;

          &::after {
            background-color: rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0.75);
          }
        }

        .collapsable {
          display: flex;
          width: 100%;
          height: 100%;
        }

        a {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          padding: 1.5em;

          @include media-breakpoint-up(sm) {
            &::before {
              content: '';
              position: absolute;
              top: 0.5rem;
              right: 1rem;
              font-size: 5.5em;
              font-weight: $font-weight-black;
              line-height: 1;
              opacity: 0;
              transform: translate(100%, -100%);
              transition: transform 0.35s ease 0.15s, opacity 0.35s ease 0.15s;
            }
          }

          /** Not collapsed for mobile */
          @include media-breakpoint-down(lg) {
            color: currentcolor;

            &::before {
              opacity: 0.25;
              transform: none;
            }
          }

          .button {
            background-color: var(--primary);
            border-color: var(--current-color);
            color: color(--white);
            display: none;
          }

          &:hover,
          &:focus {
            color: currentcolor;

            &::before {
              opacity: 0.25;
              transform: none;
            }

            .ico-arrow {
              transform: none;
            }
          }
        }

        .article-title {
          margin: 0;
          font-size: 1.5em;
          font-weight: $font-weight-bold;
          color: var(--white);

          @include media-breakpoint-up(xxl) {
            font-size: 1.75em;
          }

          @include media-breakpoint-down(md) {
            font-size: 1em;
          }

          @media screen and (max-width: 450px) {
            font-size: 0.72em;
          }
        }

        .article-text {
          @include media-breakpoint-up(xl) {
            font-size: 1.1em;
          }

          @include media-breakpoint-down(md) {
            font-size: 0.75em;
          }

          @media screen and (max-width: 450px) {
            font-size: 0.65em;
          }

          p {
            padding-top: 0.5em;
            color: var(--white);

            @include media-breakpoint-up(xl) {
              padding-top: 1em;
            }
          }
        }

        .ico-arrow {
          z-index: 9;
          display: block;
          position: absolute;
          width: 40px;
          height: 40px;
          right: 0;
          bottom: 0;
          transform: translate(100%, 100%);
          transition: transform 0.35s ease 0.15s;
        }
      }
    }
  }
}
