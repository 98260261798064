.template-featured-courses {
  .pagination {
    --current-color: var(--secondary);

    .button:hover,
    .button:focus {
      color: color(--secondary);
    }
  }
}
