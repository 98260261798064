html {
  font: calc(#{$font-size-base} + 0.105vw) / #{$line-height-base}em $font-family-base;
  font-weight: normal;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

body {
  font-family: $font-family-base;
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
     -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
          font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     -moz-font-smoothing: antialiased;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

::-moz-selection {
  color: color(--white);
  background-color: color(--secondary);
}

::selection {
  color: color(--white);
  background-color: color(--secondary);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 $headings-margin-bottom 0;
  color: inherit;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
}

h1, h2, h3,
.h1, .h2, .h3 {
  font-weight: $headings-font-weight;

  strong {
    font-weight: $headings-font-weight;
  }
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-family: $font-family-narrow;
  font-weight: $font-weight-bold;

  strong {
    font-weight: $font-weight-bold;
  }
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

p,ul {
  margin: 0 0 0.75em 0;

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  padding-left: 1em;
}

p + ul {
  margin-top: -0.75em;
}

strong {
  font-weight: $font-weight-bold;
}

a,
.icon,
.icon::before,
label {
  transition: color 0.2s ease-out;
}

[role='menuitem'] {
  transition: color 0.2s ease-out, outline 0.2s ease-out;
}

a {
  color: color(--white);
  text-decoration: $link-decoration;

  .l-dark & {
    color: color(--white);
  }

  .l-light & {
    color: color(--primary);
  }

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

address {
  font-style: normal;
}

.no-break {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}
