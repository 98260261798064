.contactbot-container {
  position: fixed;
  bottom: -100%;
  right: 1.25rem;
  width: 100%;
  max-width: 400px;
  height: calc(100vh - 6.75rem - 126px);
  max-height: 550px;
  background: var(--primary);
  background: linear-gradient(180deg, var(--primary) 0%, var(--white) 60%, var(--white) 100%);
  transition: bottom 0.5s ease, transform 0.5s ease;
  z-index: 9999;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.66);
  border-radius: 1rem;
  overflow: hidden;

  &.visible {
    bottom: 6.75rem;
  }

  .contactbot-content,
  .contectbot-form-content {
    width: 100%;
    max-width: 400px;
    transition: transform 0.5s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .contactbot-content {
    transform: translateX(0);
    position: relative;
    height: 100%;
    width: 100%;
    overflow: scroll;

    .contactbot-header {
      padding: 1.25rem;
      color: var(--white);
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 4rem;
    }

    ul {
      padding-bottom: 1.75rem;

      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 1.25rem 0.75rem 0;
        background-color: #fff;
        border-radius: 0.5rem;
        border: 1px solid var(--grey);
        cursor: pointer;
        width: calc(100% - 1.25rem);

        a {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          width: 100%;
        }

        .contactbot-list-left-content {
          padding: 0.625rem 1.25rem;
          width: 80%;
          color: var(--primary);

          .contactbot-list-title {
            color: var(--primary);
            font-size: 0.875rem;
            font-weight: bold;
          }

          .contactbot-list-subtitle {
            color: #a2a2a2;
            font-size: 0.875rem;
            font-weight: bold;
          }
        }

        .contactbot-list-right-content {
          padding: 0.625rem 1.25rem;
          width: 20%;
          color: var(--primary);
        }
      }
    }
  }

  .contectbot-form-content {
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    overflow: scroll;

    .contactbot-header {
      padding: 1.25rem;
      color: var(--white);
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: 4rem;
    }

    .form {
      margin-bottom: 6rem;

      input,
      select,
      textarea {
        width: 80%;
        margin-left: 10%;
        background-color: transparent;
        border-bottom: 1px solid rgb(0, 0, 0);
        margin-top: 1rem;
        color: var(--primary);
      }

      .button {
        border: 1px solid var(--primary);
        color: var(--primary);
        display: block;
        float: right;
        margin-right: 10%;
        margin-top: 1rem;

        &:hover,
        &:focus {
          border: 1px solid var(--primary);
          color: var(--primary);
        }
      }

      .form-field-description,
      .form-confirmation {
        color: var(--primary);
        font-size: 0.875rem;
        margin-top: 1rem;
        margin-left: 10%;
        width: 80%;
      }
    }
  }

  &.show-form {
    .contectbot-form-content {
      transform: translateX(0);
    }

    .contactbot-content {
      transform: translateX(-100%);
    }
  }

  button {
    &.close {
      position: absolute;
      right: 1.25rem;
      top: 1rem;
      z-index: 999;
      height: 1.875rem;
      width: 1.875rem;
      border-radius: 100%;
      border: none;
      background-color: var(--white);
      cursor: pointer;
    }

    &.back {
      position: absolute;
      left: 1.25rem;
      top: 1rem;
      z-index: 999;
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
  }
}

.contactbot-toggle {
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  button {
    background-color: transparent;
    border: none;
  }

  .tooltip {
    width: 135px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }

    div {
      &.social-share {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
        display: inline-block;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: 18px;
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }

  .ico-share {
    margin-right: 0.15rem;
    margin-bottom: 0.75rem;
    cursor: pointer;

    svg {
      box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.66);
      border-radius: 50%;
    }
  }

  .ico-contactbot {
    cursor: pointer;
  }
}
