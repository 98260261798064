.picto-unipop {
  transform-origin: 57.9545% center;

  #p,
  #u {
    transform-origin: 57.9545% center;
  }

  &-outline.svg {
    /** SVG Picto colors */
    svg {
      .line {
        fill: color(--secondary);
      }
    }
  }
}
