.footer {
  position: relative;
  z-index: 1;

  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding: calc(var(--container-padding-lg) / 2) 0;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    .link:focus-visible {
      outline: none;
    }
  }

  &-logo {
    min-width: 7.5rem;
    max-width: 10rem;
    margin: 1em auto 0 auto;

    @include media-breakpoint-up(sm) {
      max-width: 15rem;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 1em 0 0;
    }
  }

  &-section {
    position: relative;

    &.l-logo {
      width: 100%;
      text-align: center;

      @include media-breakpoint-up(lg) {
        width: auto;
        text-align: left;
      }
    }

    &:not(.l-logo) {
      margin-top: 2em;

      @include media-breakpoint-up(sm) {
        margin-top: 4em;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &.l-nav {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }

    &.l-contact,
    &.l-soc {
      @include media-breakpoint-up(xxs) {
        width: 50%;
      }

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }

    &.l-legal {
      width: 100%;
      padding-top: 4em;

      @include media-breakpoint-up(sm) {
        padding-top: 2em;
      }

      @include media-breakpoint-up(lg) {
        position: static;
        width: auto;
        padding-top: 0;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-title {
      margin: 0 0 0.75em;
      font-size: 1em;
      font-weight: $font-weight-bold;
    }

    .menu {
      white-space: nowrap;

      &:not(.legal-menu) {
        padding: 0 1em;
        border-left: 3px solid color(--secondary);
        font-size: 0.9em;
        font-weight: $font-weight-bold;
      }

      &.soc-menu {
        li {
          display: inline-block;

          &:not(:first-child) {
            margin-left: 0.5em;
          }

          .icon {
            background-color: color(--secondary);
            color: color(--primary);

            &:hover,
            &:focus {
              color: color(--white);
            }
          }
        }
      }

      &.legal-menu {
        font-family: $font-family-narrow;
        font-size: 0.75em;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          padding-top: 7em;
        }

        li {
          display: inline-block;
          width: 50%;

          @include media-breakpoint-up(sm) {
            display: block;
            width: 100%;
            text-align: right;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .top-button {
      position: absolute;
      left: 50%;
      transform: translate(-50%, calc(-100% - 1em));

      @include media-breakpoint-up(lg) {
        left: auto;
        right: 0;
        transform: none;
      }

      .icon {
        width: 6em;
        height: 6em;
        background-color: color(--secondary);
        color: color(--primary);
        font-size: 0.5em;
        line-height: 6em;
      }
    }
  }
}
