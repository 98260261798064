.section.flexible {
  counter-reset: block;

  .flexible-content {
    padding-top: 2em;
    padding-bottom: 2em;

    &:first-child {
      @include media-breakpoint-up(md) {
        padding-top: 4em;
      }
    }

    &:last-child {
      @include media-breakpoint-up(md) {
        padding-bottom: 4em;
      }
    }

    hr {
      margin: 0;
      border-top: none;
      border-bottom: 1px solid color(--primary);
    }

    a:not(.button) {
      font-weight: $font-weight-bold;
    }

    .button.l-plain {
      margin-top: 2em;
      background-color: $body-bg;
      border-color: $body-bg;
      color: color(--white);

      &:hover,
      &:focus {
        border-color: color(--white);
      }
    }

    .block:not(:last-child) {
      margin-bottom: 1em;
    }

    .text {
      .block {
        counter-increment: block;
        position: relative;
        z-index: 1;
        padding: 1em 1em 1em 1.5em;
        margin-top: 1.25em;
        border-left: 5px solid var(--current-color);
        color: var(--current-color);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background-color: $body-bg;
          opacity: 0.06;
        }
      }

      &-footer {
        color: inherit;
        text-decoration: underline;
        padding-bottom: 1rem;
        display: block;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .text-text {
        font-family: $font-family-narrow;
      }

      .text-title {
        padding-left: 1em;
        border-left: 4px solid color(--secondary);
        color: var(--current-color);
        font-size: 1.5em;

        @include media-breakpoint-up(md) {
          font-size: 2em;
        }
      }

      &:not(.image):not(.cols):not(.l-inline-title) .text-title {
        font-weight: $font-weight-bold;
      }

      &.l-inline-title {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: flex-start;
        }

        .text-title {
          min-width: 40%;
          padding: 0 2rem 0 0.5em;
          border-left: 5px solid var(--current-color);

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }

          &.l-font-large {
            font-size: 2em;

            @include media-breakpoint-up(xl) {
              font-size: 3.2em;
            }
          }
        }
      }

      &.l-background {
        position: relative;
        z-index: 1;
        padding: 2em;
        border-left: 5px solid var(--current-color);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background-color: $body-bg;
          opacity: 0.07;
        }

        .block {
          display: flex;
          padding: 0.5em 1em;
          background-color: color(--white);
          border: 1px solid var(--current-color);
          border-radius: 5px;
          transition: background-color 0.35s ease, color 0.35s ease;

          &::before {
            content: counter(block) '.';
            display: block;
            padding-right: 0.5em;
            font-weight: $font-weight-black;
            color: var(--current-color);
            transition: color 0.35s ease, color 0.35s ease;
          }

          &::after {
            display: none;
          }

          &:hover {
            background-color: $body-bg;
            color: color(--white);
            opacity: 0.85;

            &::before {
              color: color(--white);
            }
          }
        }
      }
    }

    .cols {
      display: flex;

      .text-title {
        font-size: 1.5em;
      }

      &-col .cols-text > *:first-child {
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;

        &-col:not(:first-child) .text-title {
          margin-top: 1em;
        }

        &-2-cols {
          .cols-col:first-child {
            margin-bottom: 1.5em;
          }

          &.l-background {
            .cols-col:last-child {
              .block:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        align-items: flex-start;

        &-2-cols {
          .cols-col {
            width: 50%;

            &:first-child {
              padding-right: calc(var(--container-padding-lg) / 2);
            }

            &:last-child {
              padding-left: calc(var(--container-padding-lg) / 2);
            }
          }

          &.l-background {
            .cols-col {
              &:first-child {
                padding-right: 1em;
              }

              &:last-child {
                padding-left: 1em;
              }
            }
          }
        }
      }

      &.text-image.cols-2-cols {
        .cols-col {
          @include media-breakpoint-down(md) {
            margin-top: 1em;
            margin-bottom: 1em;

            .image {
              max-width: 20em;
              margin: auto;
            }
          }
        }

        &.l-image-first {
          .cols-col:first-child {
            @include media-breakpoint-up(md) {
              order: 2;
              padding-left: calc(var(--container-padding-lg) / 2);
              padding-right: 0;
            }
          }

          .cols-col:last-child {
            @include media-breakpoint-up(md) {
              order: 1;
              padding-left: 0;
              padding-right: calc(var(--container-padding-lg) / 2);
            }
          }
        }
      }
    }

    .text-image {
      .image {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(var(--black-r), var(--black-g), var(--black-b), 0.16)
            linear-gradient(
              to top,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0.55) 0%,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0) 35%
            );
        }
      }
    }

    .text-image.cols {
      .text-title {
        padding-left: 1rem;
        margin-bottom: 1.5em;
        border-left: 4px solid var(--secondary);

        @include media-breakpoint-up(md) {
          font-size: 2em;
        }
      }
    }

    .image {
      position: relative;
      z-index: 1;

      .image-title {
        font-size: 1.8em;
        text-align: center;

        @include media-breakpoint-up(sm) {
          font-size: 2.4em;
        }

        @include media-breakpoint-up(md) {
          font-size: 3em;
        }

        @include media-breakpoint-up(xl) {
          font-size: 3.6em;
        }

        span {
          display: block;
          color: color(--secondary);
        }
      }

      &.l-dark {
        .image-background {
          position: absolute;
          top: 0;
          right: calc(0em - var(--container-padding));
          bottom: var(--container-padding);
          left: calc(0em - var(--container-padding));
          z-index: -1;
          background-color: color(--primary);
        }

        .image-title {
          padding: var(--container-padding-lg) 0;
          color: color(--white);
        }
      }
    }

    .overlay {
      $overlay-height-mobile: 50vh;

      position: relative;
      z-index: 1;
      min-height: $overlay-height-mobile;

      @include media-breakpoint-down(md) {
        &.is-collapsed[style],
        &.is-collapsed .overlay-button[style],
        &-button[style] .overlay-title {
          max-height: $overlay-height-mobile;
        }
      }

      .block {
        display: inline-block;
        vertical-align: top;
        padding: 0 2rem 0 0;
        border: none;
        color: color(--white);

        &:last-child {
          padding-right: 0;
        }

        ul {
          list-style: none;

          li {
            margin-bottom: 0.5em;
            line-height: $line-height-sm;
          }
        }
      }

      &-background {
        position: absolute;
        z-index: -2;
        width: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(md) {
          min-height: $overlay-height-mobile;
          max-height: $overlay-height-mobile;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background: rgba(var(--black-r), var(--black-g), var(--black-b), 0.16)
            linear-gradient(
              to top,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0.85) 0%,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0) 70%
            );
        }
      }

      &-group {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: stretch;
        }
      }

      &-button {
        position: absolute;
        width: 100%;
        min-height: $overlay-height-mobile;
        text-align: left;

        .overlay-title {
          position: absolute;
          top: 0;
          padding: 1rem;
          margin: 0;
          color: color(--white);
          // font-size: 2em;
          font-weight: $font-weight-bold;

          @include media-breakpoint-up(md) {
            top: auto;
            bottom: 0;
            padding: 2rem;
            font-size: 2em;
          }
        }
      }

      &-content {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        min-height: $overlay-height-mobile;
        color: color(--white);

        a {
          color: inherit;
          text-decoration: underline;
          margin-bottom: 7.5px;

          &:hover {
            text-decoration: none;
          }
        }

        > * {
          margin-left: 1rem;
          margin-right: 1rem;

          &:first-child {
            margin-top: 1rem;
          }

          &:last-child {
            margin-bottom: 1rem;
          }

          @include media-breakpoint-up(md) {
            top: auto;
            bottom: 0;
            margin-left: 2rem;
            margin-right: 2rem;

            &:first-child {
              margin-top: 2rem;
            }

            &:last-child {
              margin-bottom: 2rem;
            }
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background-color: var(--primary);
        }

        &-title {
          margin: 0 1rem 1rem 1rem;

          @include media-breakpoint-up(md) {
            margin: 0 2rem 2rem 2rem;
            font-size: 2em;
          }

          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin-top: 1em;
            background-color: color(--white);
          }
        }

        @include media-breakpoint-up(md) {
          .cols-2-cols {
            .cols-col {
              &:first-child {
                padding-right: 1em;
              }

              &:last-child {
                padding-left: 1em;
              }
            }
          }
        }

        .text-title {
          color: color(--white);
        }

        .text-text {
          font-family: $font-family-base;

          h4,
          h5,
          h6 {
            margin-top: 1.5em;

            &:not(:first-child) {
              margin-bottom: 0;
            }
          }

          h4,
          h5 {
            &:first-child {
              margin-bottom: 1.5em;
            }
          }
        }

        .button.l-plain {
          background-color: color(--white);
          border-color: color(--white);
          color: var(--current-color);

          &:hover,
          &:focus {
            border-color: var(--current-color);
          }
        }
      }

      &-filters {
        ul {
          padding: 0;
          margin: 1em 0 0 0;
          list-style: none;

          @include media-breakpoint-up(xs) {
            columns: 3;

            li {
              margin: 0.25em 0.5em;
            }
          }
        }

        .filters-button {
          text-align: left;
          transition: opacity 0.25s ease;

          &:hover,
          &:focus {
            color: white;
            opacity: 0.75;
          }
        }
      }

      &-100 {
        .overlay-button {
          .overlay-title {
            @include media-breakpoint-up(md) {
              max-width: 60%;
            }
          }

          .overlay-logo {
            position: absolute;
            bottom: calc(-#{$overlay-height-mobile} + 4rem);
            left: 0;
            width: 10em;
            min-width: 10em;
            max-width: 10em;
            min-height: 2.2em;
            max-height: 5em;
            transform: translateX(-1rem);

            @include media-breakpoint-up(md) {
              bottom: 0;
              transform: translate(-2rem, -2rem);
            }
          }

          span + .overlay-logo {
            @include media-breakpoint-up(md) {
              left: auto;
              right: 0;
              transform: translate(100%, -2rem);
            }
          }
        }
      }

      &-50 {
        @include media-breakpoint-down(md) {
          &:not(:last-child) {
            margin-bottom: 2em;
          }
        }

        @include media-breakpoint-up(md) {
          width: 50%;

          &:first-child {
            margin-right: 0.5rem;
          }

          &:last-child {
            margin-left: 0.5rem;
          }
        }

        p,
        ul {
          font-size: 0.9em;
        }

        .overlay-logo {
          width: 8.8em;
          min-width: 8.8em;
          max-width: 8.8em;
          min-height: 2.2em;
          max-height: 4em;
        }

        .overlay-button {
          .overlay-title {
            @include media-breakpoint-up(xl) {
              max-width: 80%;
            }
          }

          .overlay-logo {
            position: absolute;
            bottom: calc(-#{$overlay-height-mobile} + 4rem);
            left: 1rem;

            @include media-breakpoint-up(md) {
              bottom: 0;
              transform: translate(2rem, -2rem);
            }
          }

          span + .overlay-logo {
            @include media-breakpoint-up(md) {
              position: static;
              bottom: auto;
              left: auto;
              margin-top: 1em;
              transform: none;
            }
          }
        }
      }

      &-33 {
        $overlay-height-mobile: 25em;

        @include media-breakpoint-down(md) {
          min-height: $overlay-height-mobile;

          &.is-collapsed[style],
          &.is-collapsed .overlay-button[style],
          .overlay-button[style] .overlay-title {
            // height: auto;
            max-height: $overlay-height-mobile;
            // max-height: none;
          }
        }

        .overlay-background {
          @include media-breakpoint-down(md) {
            min-height: $overlay-height-mobile;
            max-height: $overlay-height-mobile;
          }
        }

        .overlay-content {
          min-height: $overlay-height-mobile;

          > * {
            @include media-breakpoint-between(md, xl) {
              margin-left: 1rem;
              margin-right: 1rem;
            }
          }
        }

        .overlay-title {
          font-size: 1.6em;
        }

        .overlay-content-title {
          color: color(--secondary);
          font-size: 1.4em;

          @include media-breakpoint-between(md, xl) {
            margin: 1rem;
          }
        }

        @include media-breakpoint-down(md) {
          &:not(:last-child) {
            margin-bottom: 2em;
          }
        }

        @include media-breakpoint-up(md) {
          width: 33%;

          &:first-child {
            margin-right: 0.5rem;
          }

          &:nth-child(2) {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }

          &:last-child {
            margin-left: 0.5rem;
          }
        }

        p,
        ul {
          font-size: 0.8em;
        }

        .overlay-button {
          .overlay-title {
            @include media-breakpoint-up(md) {
              // max-width: 80%;
            }
          }
        }
      }
    }

    .cta {
      position: relative;
      z-index: 1;
      padding: 2em;
      border-left: 5px solid var(--current-color);

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: flex-start;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: $body-bg;
        opacity: 0.17;
      }

      .cta-title {
        margin: 0;
        color: var(--current-color);

        @include media-breakpoint-up(md) {
          min-width: 6em;
          font-size: 2.2em;
        }

        @include media-breakpoint-up(xl) {
          min-width: 8em;
        }
      }

      .cta-text {
        padding: 2em 0;
        font-family: $font-family-narrow;

        @include media-breakpoint-up(md) {
          flex-grow: 1;
          padding: 0 2em;
        }
      }

      .button {
        text-align: center;

        @include media-breakpoint-up(md) {
          min-width: 10em;
        }

        &.l-plain {
          text-transform: uppercase;
        }
      }
    }

    .filters {
      margin-top: -2em;

      ul {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 0.5em);
        padding: 0;
        margin: -0.25em;
        list-style: none;

        @include media-breakpoint-up(xs) {
          width: calc(100% + 1em);
          margin: -0.5em;
        }

        @include media-breakpoint-up(xl) {
          width: calc(100% + 2em);
          margin: -1em;
        }
      }

      li {
        width: calc(50% - 0.51em);
        margin: 0.25em;

        @include media-breakpoint-up(xs) {
          width: calc(50% - 1.01em);
          margin: 0.5em;
        }

        @include media-breakpoint-up(md) {
          width: calc(25% - 1.01em);
        }

        @include media-breakpoint-up(xl) {
          width: calc(25% - 2.01em);
          margin: 1em;
        }
      }

      .button {
        display: block;
        width: 100%;

        &:hover,
        &:focus {
          .filters-filter {
            background-size: 110%;

            &::after {
              transform: scale(1.2);
            }
          }
        }
      }

      &-filter {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        background-position: center;
        background-size: 100%;
        transition: background-size 0.35s ease;

        &::before {
          content: '';
          display: block;
          padding-top: 66.6667%;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background: rgba(var(--black-r), var(--black-g), var(--black-b), 0.16)
            linear-gradient(
              to top,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0.85) 0%,
              rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0) 35%
            );
          transition: transform 0.35s ease;
        }
      }

      .filter-title {
        position: absolute;
        right: 0.5em;
        bottom: 0.5em;
        left: 0.5em;
        color: color(--white);
        font-size: 0.9em;
        font-weight: $font-weight-bold;
        line-height: $line-height-sm;
        text-align: left;

        @include media-breakpoint-up(xxs) {
          line-height: $line-height-base;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.1em;
        }

        @include media-breakpoint-up(xl) {
          right: 1em;
          bottom: 1em;
          left: 1em;
        }
      }
    }
  }

  &.l-dark {
    --current-color: var(--white);

    padding-top: 0;
    padding-bottom: calc(var(--container-padding-lg) * 2);
    background-color: color(--primary);
    color: color(--white);

    .button.l-plain {
      color: color(--primary);

      &:hover,
      &:focus {
        border-color: color(--primary);
      }
    }

    .flexible-content {
      .text {
        &.l-inline-title {
          .text-title {
            border-color: color(--secondary);
          }
        }
      }

      .text-image.cols {
        .cols-col .text-title {
          border-color: color(--secondary);
        }
      }
    }

    .overlay-content,
    .overlay-content .text-title,
    .overlay-content-title {
      color: color(--primary);
    }

    .overlay-content {
      .button.l-plain {
        background-color: color(--primary);
        border-color: color(--primary);
        color: color(--white);

        &:hover,
        &:focus {
          border-color: color(--white);
        }
      }
    }

    .overlay-content-title::after {
      background-color: color(--primary);
    }
  }
}
