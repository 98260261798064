html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  --current-color: var(--primary);
  --current-color-r: var(--primary-r);
  --current-color-g: var(--primary-g);
  --current-color-b: var(--primary-b);

  background-color: $body-bg;
  color: $body-color;
}

#main {
  background-color: $body-bg;
  color: $body-color;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.trainings-lan,
.template-trainings-lan {
  --current-color: var(--red);
  --current-color-r: var(--red-r);
  --current-color-g: var(--red-g);
  --current-color-b: var(--red-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-tic,
.template-trainings-tic {
  --current-color: var(--green);
  --current-color-r: var(--green-r);
  --current-color-g: var(--green-g);
  --current-color-b: var(--green-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-art,
.template-trainings-art {
  --current-color: var(--gold);
  --current-color-r: var(--gold-r);
  --current-color-g: var(--gold-g);
  --current-color-b: var(--gold-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-cit,
.template-trainings-cit {
  --current-color: var(--blue);
  --current-color-r: var(--blue-r);
  --current-color-g: var(--blue-g);
  --current-color-b: var(--blue-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-scm,
.template-trainings-scm {
  --current-color: var(--brown);
  --current-color-r: var(--brown-r);
  --current-color-g: var(--brown-g);
  --current-color-b: var(--brown-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-sch,
.template-trainings-sch {
  --current-color: var(--purple);
  --current-color-r: var(--purple-r);
  --current-color-g: var(--purple-g);
  --current-color-b: var(--purple-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-acp,
.template-trainings-acp {
  --current-color: var(--orange);
  --current-color-r: var(--orange-r);
  --current-color-g: var(--orange-g);
  --current-color-b: var(--orange-b);

  background-color: var(--white);
  color: var(--primary);
}

.trainings-cdv,
.template-trainings-cdv {
  --current-color: var(--secondary);
  --current-color-r: var(--secondary-r);
  --current-color-g: var(--secondary-g);
  --current-color-b: var(--secondary-b);

  background-color: var(--white);
  color: var(--primary);
}
