/** SVG Logo colors */
.logo-unipop.svg {
  svg {
    .primary {
      fill: color(--primary);
    }

    .secondary {
      fill: color(--secondary);
    }

    .text {
      fill: color(--white);
      transition: fill 0.35s ease;
    }
  }
}

.is-scrolled {
  .logo-unipop.svg {
    svg {
      .text {
        fill: color(--primary);
      }
    }
  }
}

.l-light {
  .logo-unipop.svg {
    svg {
      .text {
        fill: color(--primary);
        transition: fill 0.35s ease;
      }
    }
  }
}
