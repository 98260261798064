.header {
  --container-padding-lg: 0;
  --header-margin: 0.25em;

  @include media-breakpoint-up(sm) {
    --container-padding-lg: inherit;
    --header-margin: 0.5em;

    &.is-scrolled {
      --container-padding-lg: 1.6125vw;
      --header-margin: 0.25em;

      color: var(--primary);
    }
  }

  @include media-breakpoint-up(lg) {
    --header-margin: 2em;

    &.is-scrolled {
      --container-padding-lg: 3.225vw;
      --header-margin: 1em;

      color: var(--primary);
    }
  }

  @include media-breakpoint-up(xl) {
    --header-margin: 5em;

    &.is-scrolled {
      --header-margin: 2.5em;

      color: var(--primary);
    }
  }

  position: relative;
  z-index: 10;
  width: 100%;
  height: var(--header-height);
  background-color: color(--white);

  .container {
    transition: padding 0.35s ease;
  }

  &.l-dark {
    background-color: $body-bg;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    width: calc(100% - var(--container-padding-lg) * 2);
    height: var(--header-height);
    padding: 0 1em;
    background-color: color(--white);
    box-shadow: 0 0 24px -8px rgba(#fff, 0);
    font-weight: $font-weight-medium;
    line-height: $line-height-sm;
    // prettier-ignore
    // transition: width 0.35s ease, padding 0.35s ease, background-color 0.35s ease, color 0.35s ease, box-shadow 0.35s ease;

    @include media-breakpoint-up(sm) {
      left: auto;
      right: auto;
      height: calc(var(--header-height) - 2em);
      margin-top: 1em;
    }

    .header.is-scrolled & {
      box-shadow: 0 0 24px -8px rgba(#000, 0.16);
    }

    .header:not(.is-scrolled) & {
      @include media-breakpoint-up(sm) {
        padding: 0;
      }
    }

    .header.l-dark:not(.is-scrolled) & {
      background-color: $body-bg;
      color: $body-color;
    }
  }

  &-logo {
    width: 4em;
    min-width: 4em;
    margin: 0;
    font-size: 1em;
    transition: width 0.35s ease, min-width 0.35s ease;

    @include media-breakpoint-up(md) {
      width: 9em;
      min-width: 9em;

      .header.is-scrolled & {
        width: 7.75em;
        min-width: 7.75em;
      }
    }

    img {
      display: block;
      height: 3.2em;
      transition: height 0.35s ease;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .svg {
      display: none;
      width: 4em;

      @include media-breakpoint-up(md) {
        display: block;
        width: 9em;
        height: 3.2em;
        transition: height 0.35s ease;

        .header.is-scrolled & {
          width: 7.75em;
          height: 2.75em;
        }
      }
    }
  }

  &-nav {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    a {
      color: inherit;
    }

    .button.overlay-button {
      @include media-breakpoint-up(xl) {
        white-space: nowrap;
      }
    }

    &-primary {
      /**
       * @see header/_nav-primary.scss
       */
    }

    &-languages {
      min-width: 3.5em;
      margin-right: var(--header-margin);

      @include media-breakpoint-down(xxs) {
        .header.is-scrolled & {
          @include visually-hidden;
        }
      }

      @include media-breakpoint-up(sm) {
        min-width: 4.5em;
      }

      .button.overlay-button {
        text-transform: uppercase;
      }

      .menu {
        padding: 0.5em 0;
      }
    }

    &-quicknav {
      flex-grow: 1;
      margin: 0 var(--header-margin);

      > .menu {
        display: flex;
        align-items: center;
        word-break: keep-all;
        overflow-wrap: normal;

        a {
          display: inline-block;
          vertical-align: middle;
          padding: 0.5em;

          @include media-breakpoint-up(sm) {
            padding: 0.5em 1em;
          }

          &:hover,
          &:focus {
            color: color(--secondary);
          }
        }

        .menu {
          padding: 0.5em 0;

          a {
            padding: 0;

            &:hover,
            &:focus {
              outline: none;
            }
          }

          &-title {
            @include visually-hidden;

            .header.is-scrolled & {
              @include media-breakpoint-up(xl) {
                @include visually-visible;

                color: var(--current-color);

                span {
                  padding-right: 1em;
                }
              }

              @include media-breakpoint-up(xxl) {
                white-space: nowrap;
              }
            }
          }

          &-trainings {
            white-space: nowrap;

            @include media-breakpoint-down(xs) {
              @include visually-hidden;
            }

            .nav-overlay {
              @include media-breakpoint-down(md) {
                white-space: break-spaces;
              }

              .header.is-scrolled & {
                // right: 0;
              }
            }
          }

          &-search {
            flex-grow: 1;
          }

          &-switch {
            white-space: nowrap;
          }

          /**
          * * Ordering of elements
          *
          * Other properties are above, keep them apart for readability
          */
          // stylelint-disable no-duplicate-selectors

          &-title {
            order: 1;
          }

          &-trainings {
            order: 2;

            .header.is-scrolled & {
              // order: 3;
            }
          }

          &-search {
            order: 3;

            .header.is-scrolled & {
              // order: 2;
            }
          }

          &-switch {
            order: 3;
          }
          // stylelint-enable no-duplicate-selectors
        }
      }
    }

    &-quicknav,
    &-languages {
      .nav-overlay {
        position: absolute;
        margin-top: 0.5em;
        background-color: color(--white);
        box-shadow: 0 0 24px -8px rgba(#000, 0.16);

        @media (prefers-reduced-motion: no-preference) {
          animation: appear 0.25s ease-in 0s alternate 1 backwards;

          &.is-closing {
            opacity: 0;
            transition: opacity 0.25s ease;
          }
        }

        .header.l-dark:not(.is-scrolled) & {
          background-color: color(--primary);
        }

        .menu {
          li[role='menuitem'] {
            padding: 0.5em;

            @include media-breakpoint-up(sm) {
              padding: 0.5em 1em;
            }

            &:hover,
            &:focus {
              outline: 1px dotted color(--secondary);
              color: color(--secondary);
            }
          }
        }
      }
    }
  }
}
