.section.feeds {
  position: relative;
  overflow: hidden;
  padding-top: calc(var(--container-padding-lg) / 2);
  z-index: 2;
  padding-bottom: 6em;

  .post {
    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      &-logo {
        width: 50px;
        height: auto;
      }

      &-title {
        display: flex;
        flex-direction: column;

        &-name {
          font-size: 0.75rem;
          font-weight: bold;
          margin: 0;
          color: var(--primary);
          line-height: 0.75rem;
        }

        &-date {
          font-size: 0.6em;
          margin: 0;
          color: rgba(0, 0, 0, 0.5);
          font-weight: bold;
        }
      }
    }
  }

  .post-footer {
    text-align: right;
    font-size: 0.75em;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 3px;
    margin: 0;

    .social-share {
      width: auto;
      height: 16px;
      padding: 0 4px;
    }

    .share-button {
      display: inline-block;
      position: relative;
      cursor: pointer;
      color: var(--secondary);
      font-weight: bold;
    }

    .fb-link {
      font-weight: bold;
      color: var(--secondary);

      &:hover {
        color: var(--secondary);
        text-decoration: underline;
      }
    }

    .tooltip::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: 18px;
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }

  .feed-link,
  .hashtag-link {
    color: var(--secondary);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .see-more {
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  video {
    height: 100%;
    margin: 0 auto;
  }

  .seeall-button {
    margin: 2em 0;

    @include media-breakpoint-up(xs) {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }
  }

  .section {
    &-title {
      color: color(--secondary);
      font-size: 3em;
    }

    &-content {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      align-items: flex-start;
      justify-content: flex-start;
      width: calc(100% + 1em);
      margin: 2em -0.5em 0 -0.5em;

      @include media-breakpoint-up(md) {
        width: calc(100% + 2em);
        margin: 2em -1em 0 -1em;
      }

      &-post {
        position: relative;
        z-index: inherit;
        width: calc(100% - 1.01em);
        overflow: hidden;
        margin: 0.5em;
        background-color: #fff;
        border-radius: 11px;
        color: #000;
        padding: 1rem;
        padding-bottom: 0.25rem;
        box-shadow: 0 0 10px -4px rgba(100, 100, 100, 0.8);

        @include media-breakpoint-up(xs) {
          width: calc(50% - 1.01em);
        }

        @include media-breakpoint-up(md) {
          width: calc(33.333% - 2.01em);
          margin: 1em;
        }

        @include media-breakpoint-up(lg) {
          width: calc(25% - 2.01em);
          margin: 1em;
        }

        .post-content {
          display: flex;
          flex-direction: column;

          time {
            display: inline-block;
            margin: 1em;
            padding: 0.25em 0.5em;
            background-color: color(--white);
            color: color(--primary);
            font-family: $font-family-narrow;
            font-weight: $font-weight-medium;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }

        .post-image {
          position: relative;
          order: 1;
          background-color: color(--grey);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          transition: background-size 0.35s ease;
          text-align: center;
          overflow: hidden;

          &::before {
            content: '';
            padding-top: 68.5%;
            float: left;
          }

          &-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur(5px);
            scale: 1.1;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 22, 74, 0.3);
            }
          }

          &-foreground {
            position: relative;
            z-index: 1;
            display: block;
            height: 215px;
            transition: transform 0.35s ease;
            width: auto;
            margin: 0 auto;
          }

          &:hover .post-image-foreground {
            transform: scale(1.05);
          }
        }

        a {
          &:hover,
          &:focus {
            color: currentcolor;

            .post-image {
              background-size: auto 105%;
            }
          }
        }
      }

      p.no-result {
        margin: 0 1em;
      }
    }
  }

  .post-content-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;

    .feed-post {
      &-media {
        position: relative;
        padding-top: 2rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 0;

        .hover {
          position: absolute;
          top: 2em;
          left: 6px;
          right: 6px;
          bottom: 0;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.4);
          opacity: 0;
          transition: opacity 0.35s ease;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        .feed-image-first {
          width: calc(100% - 12px);
          object-fit: cover;
          height: auto;
        }

        .feed-image-col {
          &-0,
          &-1,
          &-2 {
            width: calc(50% - 6px);
            object-fit: cover;
            height: auto;
          }
        }

        .feed-image {
          width: calc(33.333% - 4px);
          object-fit: cover;
          height: auto;
        }

        .feed-single-image {
          width: calc(100% - 12px);
          object-fit: cover;
          height: auto;
        }

        .video-thumbnail-wrapper {
          position: relative;
          display: inline-block;
          cursor: pointer;
          width: 100%;

          .feed-single-image {
            margin-left: 6px;
          }

          .play-icon-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            & svg {
              & circle {
                fill: rgba(0, 0, 0, 0.7);
              }
            }
          }
        }

        .image-wrapper {
          position: relative;
          width: calc(33.333% - 4px);

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }

        .image-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.7);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          font-weight: bold;
        }
      }

      &-message {
        width: calc(100% - 12px);
        margin-left: 6px;
        font-size: 0.8em;
        line-height: 1.3em;
      }
    }
  }
}
