.template-front-page {
  --current-color: var(--yellow);

  .section.trainings-list {
    .section-title {
      --current-color: var(--secondary);
    }
  }

  .picto-unipop-outline.svg {
    --current-color: var(--secondary);
  }
}
