.template-infos {
  .section.flexible {
    .flexible-content {
      &:first-child {
        padding-bottom: 4em;

        @include media-breakpoint-up(md) {
          padding-bottom: 6em;
        }

        .text-image.cols {
          .text-title {
            @include media-breakpoint-up(md) {
              font-size: 3em;
            }

            @include media-breakpoint-up(xxl) {
              font-size: 3.25em;
            }
          }
        }
      }

      &:not(:first-child):not(:last-child) {
        .text {
          .text-title {
            position: relative;
            z-index: 1;
            // padding-top: 5rem;
            // padding-left: 5rem;
            min-height: 4.9583em;
            padding-top: 1.65em;
            padding-left: 2.2em;
            border: none;

            @include media-breakpoint-up(md) {
              font-size: 2.4em;
            }

            &::after {
              content: '';
              position: absolute;
              // top: 5rem;
              top: 1.65em;
              right: 0;
              bottom: 0;
              // left: 5rem;
              left: 2em;
              z-index: -1;
              background-color: color(--white);
            }
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(9) {
          background-color: var(--grey-pale);

          .text {
            .text-title {
              &::after {
                background-color: var(--grey-pale);
              }
            }
          }

          .block::after {
            opacity: 0;
          }
        }

        &:nth-child(2) {
          // nb 1
          padding-top: 4em;

          @include media-breakpoint-up(md) {
            padding-top: 6em;
          }

          .text-title {
            @include media-breakpoint-up(md) {
              max-width: 70%;
            }
          }
        }

        &:nth-child(3) {
          padding-bottom: 4em;

          @include media-breakpoint-up(md) {
            padding-bottom: 6em;
          }
        }

        &:nth-child(4) {
          // nb 2
          padding-top: 4em;
          padding-bottom: 4em;

          @include media-breakpoint-up(md) {
            padding-top: 6em;
            padding-bottom: 6em;
          }

          .text {
            .text-title {
              padding-left: 2.7em;

              &::after {
                left: 2.5em;
              }
            }
          }
        }

        &:nth-child(5) {
          // nb 3
          padding-top: 4em;

          @include media-breakpoint-up(md) {
            padding-top: 6em;
          }

          .text {
            .text-title {
              padding-left: 2.82em;

              @include media-breakpoint-up(md) {
                max-width: 50%;
              }

              &::after {
                left: 2.62em;
              }
            }
          }
        }

        &:nth-child(6) {
          padding-bottom: 4em;

          @include media-breakpoint-up(md) {
            padding-bottom: 6em;
          }
        }

        &:nth-child(7) {
          // nb 4
          padding-top: 4em;

          @include media-breakpoint-up(md) {
            padding-top: 6em;
          }

          .text {
            .text-title {
              max-width: 75%;
              padding-left: 3.4em;

              &::after {
                left: 3.2em;
              }
            }
          }
        }

        &:nth-child(8) {
          padding-bottom: 4em;

          @include media-breakpoint-up(md) {
            padding-bottom: 6em;
          }
        }
      }

      &:last-child {
        padding-top: 4em;
        padding-bottom: 4em;
        background-color: color(--primary);
        color: color(--white);
        text-align: center;

        @include media-breakpoint-up(md) {
          padding-top: 6em;
          padding-bottom: 6em;
        }

        .text {
          .text-title {
            padding-left: 0;
            border: none;
            color: color(--secondary);

            &::after {
              content: '';
              display: block;
              width: 2em;
              height: 1px;
              margin: 1em auto;
              border-bottom: 1px solid color(--white);
            }
          }

          .text-text {
            @include media-breakpoint-up(md) {
              max-width: 50%;
              margin: 0 auto;
            }
          }
        }
      }

      .headings-numbers {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 50px;
        // height: 11.9rem;
        height: 4.9583em;
        background: url('../../../images/numbers.svg') no-repeat left center / cover;

        &.number-1 {
          // width: 5.85rem;
          width: 2.4375em;
        }

        &.number-2 {
          // background-position: -6.25rem center;
          background-position: -2.6042em center;
          // width: 9.2rem;
          width: 3.8333em;
        }

        &.number-3 {
          // background-position: -16rem center;
          background-position: -6.6667em center;
          // width: 9.45rem;
          width: 3.9375em;
        }

        &.number-4 {
          // background-position: -26rem center;
          background-position: -10.8333em center;
          // width: 10.75rem;
          width: 4.4792em;
        }
      }
    }
  }
}
