.header-form-search {
  display: flex;

  &::before {
    @include media-breakpoint-up(sm) {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 1.5em;
      border-right: 1px solid color(--primary);
      transform: translateY(0.4em);

      @include media-breakpoint-up(md) {
        margin: 0 1em;
      }

      .header.l-dark:not(.is-scrolled) & {
        border-color: color(--white);
      }

      .header.is-front-page.is-scrolled & {
        border-color: color(--primary);
      }
    }
  }

  .button.submit-button {
    order: 1;
    padding: 0.5em 1em;
    line-height: inherit;

    @include media-breakpoint-up(sm) {
      padding-right: 0.5em;
    }

    .icon {
      font-size: 0.75em;
    }
  }

  .form-field {
    flex-grow: 1;
    order: 2;

    label {
      @include visually-hidden;
    }

    &-control {
      position: relative;

      input {
        width: 100%;
        background-color: transparent;
        transition: color 0.35s ease;

        &::-webkit-search-cancel-button {
          appearance: none;
        }

        @include media-breakpoint-up(sm) {
          min-width: 10em;
        }

        &:placeholder-shown::placeholder {
          opacity: 1;
        }

        &:focus,
        &:focus-visible {
          border: 1px dotted color(--secondary);
          outline: none;
        }
      }

      .button.clear-button {
        position: absolute;
        top: 0.5em;
        right: 0.5em;

        .icon {
          font-size: 0.75em;
        }
      }
    }
  }
}

.header-form-search-overlay {
  position: absolute;
  top: var(--header-height);
  right: 0;
  left: 0;
  // z-index: 1;
  max-height: 20em;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(color(--primary-r), color(--primary-g), color(--primary-b), 0.5) color(--grey-light);
  scrollbar-width: thin;
  padding: 0.5em var(--container-padding-lg);
  background-color: color(--white);
  box-shadow: 0 0 24px -8px rgba(#000, 0.16);

  @include media-breakpoint-up(sm) {
    top: calc(var(--header-height) - 1em);

    .header.is-scrolled & {
      top: calc(var(--header-height) - 2em);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
    transition: top 0.25s ease;

    &.is-closing {
      opacity: 0;
      transition: opacity 0.25s ease;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: rgba(color(--black-r), color(--black-g), color(--black-b), 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(color(--white-r), color(--white-g), color(--white-b), 0.5);
  }

  .header:not(.is-scrolled) & {
    @include media-breakpoint-up(sm) {
      padding: 0.5em calc(var(--container-padding-lg) / 2);
    }
  }

  .trainings-table {
    font-size: 0.8em;

    tbody {
      td {
        padding: 0.25rem 0.25rem 0 0.25rem;

        @include media-breakpoint-up(md) {
          padding: 0.5rem;
        }

        &:first-child {
          @include media-breakpoint-up(md) {
            padding-left: 0;
          }
        }

        &:last-child {
          padding-bottom: 0.25em;

          @include media-breakpoint-up(md) {
            padding-right: 0;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .header.l-dark:not(.is-scrolled) & {
    background-color: color(--primary);

    .trainings-table {
      tbody {
        tr {
          --current-color: var(--white);
        }
      }
    }
  }

  // .menu {
  //   li {
  //     font-family: $font-family-narrow;
  //     padding: 0.75rem 0.75rem 0 0.75rem;

  //     @include media-breakpoint-up(md) {
  //       padding: 0.5rem;
  //     }

  //     @include media-breakpoint-up(lg) {
  //       padding: 1.25rem;
  //     }
  //   }
  // }
}
