.section.news {
  position: relative;
  overflow: hidden;
  padding-top: var(--container-padding-lg);
  z-index: 1;

  .seeall-button {
    margin: 2em 0;
    float: right;

    @include media-breakpoint-up(xs) {
      position: absolute;
      right: var(--container-padding-lg);
      margin: 0;
      transform: translateY(calc(-100% - 0.25em));
    }
  }

  .section {
    &-title {
      font-size: 3em;
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: calc(100% + 1em);
      margin: 2em -0.5em 0 -0.5em;

      @include media-breakpoint-up(md) {
        width: calc(100% + 2em);
        margin: 2em -1em 0 -1em;
      }

      &-article {
        position: relative;
        z-index: 1;
        width: calc(100% - 1.01em);
        overflow: hidden;
        margin: 0.5em;

        &:not(:last-of-type) {
          margin-bottom: 2em;
        }

        @include media-breakpoint-up(xs) {
          width: calc(50% - 1.01em);
        }

        @include media-breakpoint-up(md) {
          width: calc(33.3333% - 2.01em);
          margin: 1em;
        }

        .article-content {
          display: flex;
          flex-direction: column;

          time {
            display: inline-block;
            margin: 1em;
            padding: 0.25em 0.5em;
            background-color: color(--white);
            color: color(--primary);
            font-family: $font-family-narrow;
            font-weight: $font-weight-medium;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }

        .article-title {
          order: 2;
          margin: 0.75em 0 0 0;
          font-size: 1.25em;
          font-weight: $font-weight-bold;

          @include media-breakpoint-up(xl) {
            font-size: 1.5em;
          }
        }

        .article-image {
          position: relative;
          order: 1;
          background-color: color(--grey);
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          transition: background-size 0.35s ease;
          text-align: center;
          overflow: hidden;

          &::before {
            content: '';
            padding-top: 68.5%;
            float: left;
          }

          &-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur(5px);
            scale: 1.1;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 22, 74, 0.3);
            }
          }

          &-foreground {
            position: relative;
            z-index: 1;
            display: block;
            height: 363px;
            transition: transform 0.35s ease;
            width: auto;
            margin: 0 auto;
          }

          &:hover .article-image-foreground {
            transform: scale(1.05);
          }
        }

        a {
          &:hover,
          &:focus {
            color: currentcolor;

            .article-image {
              background-size: auto 105%;
            }
          }
        }
      }

      p.no-result {
        margin: 0 1em;
      }
    }
  }
}
