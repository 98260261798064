.section.cta.cta-multiple {
  padding-top: var(--container-padding-lg);
  overflow: hidden;
  z-index: 1;

  .section {
    &-title {
      margin: 0 0 1.5em 0;
      font-size: 2.4em;
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: calc(100% + 1em);
      padding: 0;
      margin: -0.5em;
      list-style: none;

      &-article {
        position: relative;
        z-index: 1;
        width: calc(100% - 1.01em);
        padding: 1.5em;
        margin: 0.5em;
        transition: background-position 0.35s ease, background-size 0.35s ease;

        @include media-breakpoint-up(sm) {
          width: calc(50% - 1.01em);
        }

        &:nth-child(odd) {
          background-color: color(--grey);
        }

        &:nth-child(even) {
          background-color: color(--secondary);
        }

        &:nth-child(1) {
          background: url('../../../images/pro_comp.png') no-repeat 35% bottom / auto 102%;
          // background: color(--grey) url('../../../images/cta-girl.jpg') no-repeat 35% bottom / auto 100%;
        }

        &:nth-child(2) {
          background: url('../../../images/formal_exp.png') no-repeat 35% bottom / auto 102%;
          // background: color(--grey) url('../../../images/cta-man.jpg') no-repeat 35% bottom / auto 100%;
        }

        &:nth-child(1),
        &:nth-child(2) {
          @include media-breakpoint-up(xs) {
            background-position: 0% bottom;
          }

          @include media-breakpoint-up(sm) {
            background-position: 35% bottom;
          }

          @include media-breakpoint-up(xl) {
            background-position: 0% bottom;
          }
        }

        &::before {
          content: '';
          padding-top: 44%;
          float: left;

          @include media-breakpoint-up(xs) {
            padding-top: 38%;
          }

          @include media-breakpoint-up(sm) {
            padding-top: 44%;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;

          /* background-image: linear-gradient(to left, rgba(0, 0, 0, 1) -30%, rgba(0, 0, 0, 0) 70%);
          opacity: 0.95;
          transition: background-color 0.35s ease; */
        }

        &:hover {
          background-position: 32% bottom;
          background-size: auto 105%;

          @include media-breakpoint-up(xs) {
            background-position: 13% bottom;
          }

          @include media-breakpoint-up(sm) {
            background-position: 32% bottom;
          }

          @include media-breakpoint-up(xl) {
            background-position: 7% bottom;
          }

          &::after {
            /* background-color: rgba(0, 0, 0, 0.3); */
          }
        }

        a {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          height: 100%;

          &:hover,
          &:focus {
            color: currentcolor;
          }
        }

        .article-title {
          margin: 0 0 0 47.5%;
          font-size: 0.9em;
          font-weight: $font-weight-normal;
          line-height: $line-height-sm;

          @include media-breakpoint-up(xxs) {
            margin-left: 50%;
          }

          @include media-breakpoint-up(sm) {
            margin-left: 47.5%;
          }

          @include media-breakpoint-up(xl) {
            margin-left: 55%;
          }

          strong {
            display: block;
            font-size: 1.3em;
            font-weight: $font-weight-bold;

            @include media-breakpoint-up(xs) {
              font-size: 1.6em;
            }

            @include media-breakpoint-up(sm) {
              font-size: 1.3em;
            }

            @include media-breakpoint-up(md) {
              font-size: 1.5em;
            }

            @include media-breakpoint-up(lg) {
              font-size: 1.6em;
            }

            @include media-breakpoint-up(xl) {
              font-size: 1.7em;
            }

            @include media-breakpoint-up(xxl) {
              font-size: 2.2em;
            }

            @include media-breakpoint-up(xxxl) {
              font-size: 2.3em;
            }
          }
        }
      }
    }
  }
}

.section.cta.cta-unique {
  padding-top: var(--container-padding-lg);

  .container {
    display: flex;
    align-items: stretch;

    &::before {
      content: '';
      padding-top: 24%;
      float: left;
      border-left: 5px solid color(--secondary);
    }
  }

  .cta-content {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: calc(100% - 4px);
    background: url('../../../images/cta-woman.jpg') no-repeat right center / cover;

    @include media-breakpoint-up(md) {
      background-size: contain;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-image: linear-gradient(to right, rgba(14, 29, 82, 1) 30%, rgba(14, 29, 82, 0) 80%);
    }
  }

  .section {
    &-title {
      padding: 1.5em 1.5em 0 0;
      margin: 0 0 0 calc(var(--container-padding-lg) * 1.5);

      @include media-breakpoint-up(md) {
        padding-top: calc(var(--container-padding-lg) * 2);
        font-size: 2em;
      }

      @include media-breakpoint-up(lg) {
        padding-top: calc(var(--container-padding-lg) / 1.25);
        font-size: 3em;
      }

      @include media-breakpoint-up(xxxl) {
        padding-top: var(--container-padding-lg);
      }
    }

    &-content {
      height: 100%;
      padding: 0;
      margin: 1.5em 0 0 calc(var(--container-padding-lg) * 1.5);
      list-style: none;
      padding-bottom: 1.5em;

      @include media-breakpoint-up(sm) {
        padding-bottom: calc(var(--container-padding-lg) * 2);
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: calc(var(--container-padding-lg) / 1.25);
      }
    }
  }
}
