.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    margin-bottom: 0.5em;
  }

  .page-button {
    padding-top: 0.25em;
    margin: 0 1em;
    font-size: 1.5em;

    &.is-disabled {
      opacity: 0.5;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus {
      color: var(--current-color);
    }
  }

  .filter-overlay {
    top: 0;
    transform: translateY(-100%);
  }
}
