.section.contact {
  padding: 2em 0;
  background-color: color(--primary);
  color: color(--white);

  @include media-breakpoint-up(md) {
    padding: 4em 0;
  }

  @include media-breakpoint-up(xl) {
    padding: var(--container-padding-lg) 0;
  }

  .contact-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  .contact-group {
    &:first-child {
      @include media-breakpoint-up(md) {
        width: 60%;
        padding-right: calc(var(--container-padding-lg) / 2);
      }
    }

    &:last-child {
      @include media-breakpoint-down(md) {
        &::before {
          content: '';
          display: block;
          width: 50%;
          margin: 1em auto;
          border-top: 1px solid color(--secondary);
        }
      }

      @include media-breakpoint-up(md) {
        width: 40%;
        padding-left: calc(var(--container-padding-lg) / 2);
        border-left: 1px solid color(--secondary);
      }
    }

    .h4 {
      font-family: $font-family-base;
    }
  }

  .contact-title {
    color: color(--secondary);
    font-size: 2.4em;

    @include media-breakpoint-up(sm) {
      font-size: 3em;
    }

    @include media-breakpoint-up(md) {
      font-size: 3.6em;
    }

    @include media-breakpoint-up(xl) {
      font-size: 4.2em;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 4.8em;
    }
  }

  .contact-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .link {
      color: color(--white);

      .icon {
        margin-right: 0.5em;
        color: color(--secondary);
      }

      &:hover,
      &:focus {
        color: color(--secondary);

        .icon {
          color: color(--white);
        }
      }
    }
  }
}
