@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-XLight.woff2') format('woff2'), url('GothamNarrow-XLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-XLightItalic.woff2') format('woff2'), url('GothamNarrow-XLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-Light.woff2') format('woff2'), url('GothamNarrow-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-LightItalic.woff2') format('woff2'), url('GothamNarrow-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-Book.woff2') format('woff2'), url('GothamNarrow-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-BookItalic.woff2') format('woff2'), url('GothamNarrow-BookItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-Medium.woff2') format('woff2'), url('GothamNarrow-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-MediumItalic.woff2') format('woff2'), url('GothamNarrow-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-Bold.woff2') format('woff2'), url('GothamNarrow-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-BoldItalic.woff2') format('woff2'), url('GothamNarrow-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-Black.woff2') format('woff2'), url('GothamNarrow-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-BlackItalic.woff2') format('woff2'), url('GothamNarrow-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-Ultra.woff2') format('woff2'), url('GothamNarrow-Ultra.woff') format('woff');
  font-weight: 1000;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: GothamNarrow;
  src: url('GothamNarrow-UltraItalic.woff2') format('woff2'), url('GothamNarrow-UltraItalic.woff') format('woff');
  font-weight: 1000;
  font-style: italic;
  font-display: swap;
} */
