.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: color(--primary);
  animation: appear 0.5s ease-in; // Must match the delay in useNavigateWithLoader.js
  transition: opacity 0.35s ease 0.25s; // The total must match the delay in Loader.jsx

  .picto-unipop {
    max-width: 10em;
    animation: appear 0.5s ease-in 0.25s normal backwards;
    transition: opacity 0.35s ease, transform 0.35s ease;

    @include media-breakpoint-up(md) {
      width: 25vw;
      max-width: 25vw;
    }

    svg {
      width: 100%;
    }

    #p {
      animation: picto-unipop-p 3s ease-in-out 0.5s alternate infinite;
    }

    #u {
      animation: picto-unipop-u 3s ease-in-out 0.5s alternate infinite;
    }
  }

  &.is-loaded {
    opacity: 0;

    .picto-unipop {
      opacity: 0;
      transform: scale(3);
    }
  }
}
