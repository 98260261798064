@media print {
  body {
    font-size: 12pt;
    line-height: 1.5;
    color: (--primary);
    background: var(--white);
  }

  .template.l-light,
  .template.l-dark {
    background: var(--white);
    color: var(--primary);
  }

  header,
  footer,
  nav,
  .no-print {
    display: none !important;
  }

  .printable {
    display: block !important;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: var(--primary);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
  }

  table,
  th,
  td {
    border: 1px solid transparent;
  }

  th,
  td {
    padding: 0.5em;
    text-align: left;
  }

  .shadow,
  .gradient {
    box-shadow: none !important;
    background: none !important;
  }

  .contactbot-container,
  .contactbot-toggle {
    display: none !important;
  }
}
