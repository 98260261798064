[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important; // stylelint-disable-line
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($char, $size: 18px, $color: #000) {
  content: $char;
  color: $color;
  font-family: 'icomoon' !important; // stylelint-disable-line
  font-size: $size;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Don't forget to add the icon to the <Icon> component PropTypes when you add an icon
 */

.icon-linkedin::before,
.icon-l::before {
  content: '\e912';
}

.icon-facebook::before,
.icon-f::before {
  content: '\e900';
}

.icon-instagram::before,
.icon-i::before {
  content: '\e901';
}

.icon-twitter::before,
.icon-t::before {
  content: '\e902';
}

.icon-youtube::before,
.icon-y::before {
  content: '\e903';
}

.icon-globe::before {
  content: '\e910';
}

.icon-email::before {
  content: '\e904';
}

.icon-clock::before {
  content: '\e905';
}

.icon-marker::before {
  content: '\e906';
}

.icon-arrow-left::before {
  content: '\e911';
}

.icon-arrow-right::before {
  content: '\e907';
}

.icon-triangle-up::before {
  content: '\e908';
}

.icon-cross::before {
  content: '\e909';
}

.icon-chevron-down::before {
  content: '\e90a';
}

.icon-search::before {
  content: '\e90b';
}

.icon-times::before {
  content: '\e90c';
}

.icon-bars::before {
  content: '\e90d';
}

.icon-plus::before {
  content: '\e90e';
}

.icon-phone::before {
  content: '\e90f';
}

.icon {
  &.l-round {
    display: inline-block;
    width: 2em;
    height: 2em;
    padding-top: 0.05em;
    border-radius: 100%;
    background-color: color(--primary);
    color: color(--white);
    font-size: 1em;
    line-height: 2em;
    text-align: center;
    transition: color 0.2s ease-out, background-color 0.2s ease-out;
  }

  &.l-square-outline {
    display: inline-block;
    width: 4em;
    height: 4em;
    border: 1px solid color(--secondary);
    color: color(--primary);
    font-size: 0.75em;
    line-height: 4em;

    .l-dark & {
      border-color: color(--white);
      color: color(--secondary);
    }
  }
}
