.checkbox-group,
.radio-group {
  .checkbox-label,
  .radio-label {
    display: flex;
    align-items: flex-start;

    &.is-disabled {
      opacity: 0.5;
    }
  }

  .checkbox,
  .radio {
    /** Must match the width of the svg in the component */
    min-width: calc(14px + 0.45em);
    margin-top: 0.1em;
    margin-right: 0.45em;

    rect {
      /** Border */
      &:first-child {
        fill: transparent;
        stroke: color(--grey);
        transition: fill 0.35s ease, stroke 0.35s ease;
      }

      /** Focus ring */
      &.focus {
        fill: none;
        stroke: var(--current-color);
      }
    }

    path {
      fill: color(--primary);
    }

    &.is-selected {
      rect:first-child {
        fill: var(--current-color);
        stroke: transparent;
      }
    }
  }
}
