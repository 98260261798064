.template {
  /**
   * Colors for trainings templates are defined in _global.scss
   */
  padding-top: 1px;

  &.l-dark {
    background-color: $body-bg;
    color: color(--white);

    /** SVG Logo colors */
    .logo-unipop.svg {
      .text {
        fill: white;
      }
    }
  }
}
