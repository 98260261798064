.section.accordion {
  .accordion {
    &-header {
      padding-bottom: 2em;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding-bottom: calc(var(--container-padding-lg) / 2);
      }

      &-title {
        font-size: 2.4em;

        @include media-breakpoint-up(sm) {
          font-size: 3em;
        }

        @include media-breakpoint-up(md) {
          font-size: 3.6em;
        }

        @include media-breakpoint-up(xl) {
          font-size: 4.2em;
        }

        &::after {
          content: '';
          display: block;
          width: 10rem;
          margin: 0.5em auto;
          border-bottom: 1px solid color(--secondary);
        }
      }
    }

    &-content {
      padding: 2em 0;

      @include media-breakpoint-up(md) {
        padding: calc(var(--container-padding-lg) / 2) 0;
      }

      .article {
        &::after {
          content: '';
          display: block;
          width: 100%;
          border-bottom: 1px solid var(--current-color);
          opacity: 0.5;
        }

        .cta-button {
          display: block;
          width: 100%;
          padding: 2em 0;
          text-align: left;
        }

        &-title {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0;
          font-size: 1.2em;
          font-weight: $font-weight-bold;

          strong {
            color: color(--secondary);
          }

          span:not(.icon) {
            flex-grow: 1;
            padding-right: 2em;
          }

          .icon {
            font-size: 0.8em;
          }
        }

        &-content {
          font-family: $font-family-narrow;

          > div {
            position: relative;
            padding: 1.5em 0 2em 2em;

            @include media-breakpoint-up(md) {
              padding-bottom: 4em;
              padding-left: 2.75em;
            }

            &::before {
              content: '';
              position: absolute;
              top: 1.5em;
              bottom: 2em;
              left: 0;
              border-left: 5px solid var(--current-color);

              @include media-breakpoint-up(md) {
                bottom: 4em;
              }
            }
          }
        }

        &-medias {
          display: flex;
          align-items: flex-end;
          padding-top: 1.5em;

          .button {
            border-color: color(--primary);
            background-color: color(--primary);
            color: color(--white);
            text-transform: uppercase;
            white-space: nowrap;

            &:hover,
            &:focus {
              border-color: color(--white);
            }
          }

          .image {
            flex-grow: 1;
            text-align: right;
            margin-bottom: -0.275em;

            img {
              display: inline-block;
              max-height: 12em;
              padding-left: 1em;

              @include media-breakpoint-up(md) {
                max-width: 50%;
                padding-left: 0;
              }
            }
          }
        }

        &-list {
          padding: 0;
          margin: 0;
          list-style: none;

          &-item {
            margin: 1em 0;
            background-color: color(--grey-pale);
            border-left: 5px solid color(--secondary);
            color: color(--primary);

            &:last-child {
              margin-bottom: 0;
            }
          }

          .cta-button {
            padding: 1em 0.5em;
            color: color(--secondary);

            @include media-breakpoint-up(md) {
              padding: 2em;
            }
          }

          .list-title {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;

            span:not(.icon) {
              flex-grow: 1;
            }

            .icon {
              font-size: 0.8em;
            }
          }

          .list-content {
            > div {
              padding: 0 0.5em 2em 0.5em;

              @include media-breakpoint-up(md) {
                padding: 0 2em 2em 2em;
              }

              &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                padding-bottom: 1em;
                border-top: 1px solid color(--primary);

                @include media-breakpoint-up(md) {
                  padding-bottom: 2em;
                }
              }
            }
          }
        }
      }
    }
  }

  &.l-dark {
    .accordion-content {
      .article {
        &::after {
          border-bottom-color: color(--white);
        }

        .cta-button {
          padding: 2.75em 0;
        }

        &-content {
          > div {
            padding: 0 0 2.75em 0;

            &::before {
              display: none;
            }
          }
        }

        &-medias {
          .button {
            border-color: color(--white);
            background-color: color(--white);
            color: color(--primary);

            &:hover,
            &:focus {
              border-color: color(--primary);
            }
          }
        }

        &-list-item {
          background-color: color(--white);

          .cta-button {
            padding: 2em;
            color: color(--primary);

            &:hover,
            &:focus {
              color: color(--secondary);
            }
          }

          .article-medias {
            .button {
              border-color: color(--primary);
              background-color: color(--primary);
              color: color(--white);

              &:hover,
              &:focus {
                border-color: color(--white);
              }
            }
          }
        }
      }
    }
  }
}
