.container {
  width: 100%;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  &.l-large {
    padding-left: var(--container-padding-lg);
    padding-right: var(--container-padding-lg);
  }

  &.l-narrow {
    padding-left: calc(var(--container-padding) * 1.25);
    padding-right: calc(var(--container-padding) * 1.25);
  }
}
