.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary);
  border-radius: 50px;
  padding: 5px;
  width: 60px;
  height: 25px;
  cursor: pointer;
  position: relative;

  .icon {
    display: block;
    width: 17px;
    height: 17px;
    z-index: 10;

    &.sun {
      margin-left: 4px;
      margin-top: -2px;

      svg {
        path {
          fill: var(--white);
        }
      }
    }

    &.moon {
      margin-right: 4px;
      margin-top: 2px;

      svg {
        path {
          fill: var(--primary);
        }
      }
    }
  }

  .knob {
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    width: 25px;
    height: 20px;
    transition: transform 0.3s ease;
    z-index: 1;
  }
}

.theme-toggle[data-theme='light'] .knob {
  transform: translateX(0);
}

.theme-toggle[data-theme='dark'] .knob {
  transform: translateX(25px);
}
