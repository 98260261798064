.template-single-featured-course {
  .section.flexible.course {
    padding-bottom: 0;
  }

  .section.flexible:not(.course).l-dark {
    padding-bottom: 4em;

    @include media-breakpoint-up(md) {
      padding-bottom: calc(var(--container-padding-lg) / 2);
    }
  }
}
