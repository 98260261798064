.section {
  padding: 4em 0;

  @include media-breakpoint-up(md) {
    padding: calc(var(--container-padding-lg) / 2) 0;
  }

  &.l-picto {
    background: url('../../images/picto-unipop-outline.svg') no-repeat calc(100% + 5em) 3em / 15em;

    @include media-breakpoint-up(md) {
      background-position: calc(100% + 8.5em) top;
      background-size: 25em;
    }
  }

  &.l-dark {
    background-color: color(--primary);
    color: color(--white);
  }

  &-title {
    margin: 0;

    span,
    strong {
      color: color(--secondary);
    }
  }
}
