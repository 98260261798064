.section.partners {
  .section {
    &-content {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      // justify-content: center;
      width: calc(100% + 1em);
      margin: 2em -0.5em 0 -0.5em;

      @include media-breakpoint-up(md) {
        width: calc(100% + 2em);
        margin: 2em -1em 0 -1em;
      }

      &-article {
        width: calc(50% - 1.01em);
        margin: 0.5em;
        text-align: center;

        @include media-breakpoint-up(xs) {
          width: calc(33.3333% - 1.01em);
        }

        @include media-breakpoint-up(md) {
          width: calc(33.3333% - 2.01em);
          margin: 1em;
        }

        @include media-breakpoint-up(lg) {
          width: calc(25% - 2.01em);
        }

        @include media-breakpoint-up(xl) {
          width: calc(20% - 2.01em);
          margin: 1em;
        }

        .article-title {
          margin: 0.75em 0 0 0;
          font-size: 1.25em;
          font-weight: $font-weight-bold;
        }

        .article-content {
          .image {
            margin: 1em;

            img {
              max-width: 8em;
              max-height: 8em;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
