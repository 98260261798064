.post-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &-navigation {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 50%;
    width: 600px;
    z-index: 0;

    &_left {
      float: left;
      width: 50px;
      height: 60px;
      cursor: pointer;
    }

    &_right {
      float: right;
      width: 50px;
      height: 60px;
      cursor: pointer;
    }
  }

  &-content {
    background: white;
    border-radius: 8px;
    position: relative;
    z-index: 11;
    max-width: 500px;
    color: #000;
    height: calc(100vh - 200px);
    margin-top: 110px;
    font-size: 0.75rem;
    overflow: scroll;

    &-image {
      &-wrapper {
        position: relative;
        width: 100%;
        height: 350px;
        overflow: hidden;
        text-align: center;

        &-event {
          height: auto;
        }

        .post-modal-image {
          margin: 0 auto;
          height: 100% !important;

          &-blurred {
            filter: blur(3px);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            background-size: contain;
            transform: scale(3);
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }

    &-text {
      padding: 1.25rem;
    }

    &-close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      border-width: 2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      z-index: 99;
    }
  }

  &-thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &-slider {
      position: relative;

      .arrow-left {
        position: absolute;
        left: 1rem;
        top: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
        width: 20px;
        height: 25px;
      }

      .arrow-right {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
        width: 20px;
        height: 25px;
      }
    }

    .post-modal-thumbnail {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 5px;
      cursor: pointer;

      &.active {
        border: 2px solid #4267b2;
      }
    }
  }
}
