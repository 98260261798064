/**
 * Whether to use css or sass variables
 * Responsive variables only works with $css-vars: true
 */
$css-vars: true !default;

/**
 * Colors
 */
$dark-colors: (
  --primary:     #00164a,
  --primary-r:   0,
  --primary-g:   22,
  --primary-b:   74,
  --secondary:   #99d5d3,
  --secondary-r: 153,
  --secondary-g: 213,
  --secondary-b: 211,
  --red:         #c34646,
  --red-r:       195,
  --red-g:       70,
  --red-b:       70,
  --green:       #30844a,
  --green-r:     48,
  --green-g:     132,
  --green-b:     74,
  --gold:        #8b7b2a,
  --gold-r:      139,
  --gold-g:      123,
  --gold-b:      42,
  --blue:        #6763b9,
  --blue-r:      103,
  --blue-g:      99,
  --blue-b:      185,
  --brown:       #96674e,
  --brown-r:     150,
  --brown-g:     103,
  --brown-b:     78,
  --purple:      #9b568e,
  --purple-r:    155,
  --purple-g:    86,
  --purple-b:    142,
  --orange:      #b25c2f,
  --orange-r:    178,
  --orange-g:    92,
  --orange-b:    47,
  --yellow:      #d9c65d,
  --yellow-r:    217,
  --yellow-g:    198,
  --yellow-b:    93,
  --white:       #fff,
  --white-r:     255,
  --white-g:     255,
  --white-b:     255,
  --black:       #000,
  --black-r:     0,
  --black-g:     0,
  --black-b:     0,
  --grey:        #e1e3e0,
  --grey-r:      225,
  --grey-g:      227,
  --grey-b:      224,
  --grey-pale:   #f0f1f4,
  --grey-pale-r: 240,
  --grey-pale-g: 241,
  --grey-pale-b: 244,
  --bg:          #00164a,
  --bg-r:        0,
  --bg-g:        22,
  --bg-b:        74,
);

$light-colors: (
  --primary:     #00164a,
  --primary-r:   0,
  --primary-g:   22,
  --primary-b:   74,
  --secondary:   #99d5d3,
  --secondary-r: 153,
  --secondary-g: 213,
  --secondary-b: 211,
  --red:         #c34646,
  --red-r:       195,
  --red-g:       70,
  --red-b:       70,
  --green:       #30844a,
  --green-r:     48,
  --green-g:     132,
  --green-b:     74,
  --gold:        #8b7b2a,
  --gold-r:      139,
  --gold-g:      123,
  --gold-b:      42,
  --blue:        #6763b9,
  --blue-r:      103,
  --blue-g:      99,
  --blue-b:      185,
  --brown:       #96674e,
  --brown-r:     150,
  --brown-g:     103,
  --brown-b:     78,
  --purple:      #9b568e,
  --purple-r:    155,
  --purple-g:    86,
  --purple-b:    142,
  --orange:      #b25c2f,
  --orange-r:    178,
  --orange-g:    92,
  --orange-b:    47,
  --yellow:      #d9c65d,
  --yellow-r:    217,
  --yellow-g:    198,
  --yellow-b:    93,
  --white:       #fff,
  --white-r:     255,
  --white-g:     255,
  --white-b:     255,
  --black:       #000,
  --black-r:     0,
  --black-g:     0,
  --black-b:     0,
  --grey:        #e1e3e0,
  --grey-r:      225,
  --grey-g:      227,
  --grey-b:      224,
  --grey-pale:   #f0f1f4,
  --grey-pale-r: 240,
  --grey-pale-g: 241,
  --grey-pale-b: 244,
  --bg:          #e9f5f7,
  --bg-r:        233,
  --bg-g:        245,
  --bg-b:        247,
);

@if $css-vars {
  :root {
    @each $color, $value in $dark-colors {
      #{$color}: #{$value};
    }
  }

  [data-theme="light"] {
    @each $color, $value in $light-colors {
      #{$color}: #{$value};
    }
  }
}

/**
 * Breakpoints
 */
$breakpoints: (
  width-xxs:    460px,
  width-xs:     576px,
  width-sm:     768px,
  width-md:     992px,
  width-lg:    1200px,
  width-xl:    1560px,
  width-xxl:   1900px,
  width-xxxl:  2400px,
  height-xxs:   320px,
  height-xs:    420px,
  height-sm:    540px,
  height-md:    660px,
  height-lg:    800px,
  height-xl:    940px,
  height-xxl:  1080px,
  height-xxxl: 1220px
);

/**
 * Grid
 */
$grid-columns: 12;

$grid-gutter-width:    1.25rem;
$grid-gutter-width-xl: 2.5rem;

/**
 * Fonts
 */
$font-family-base: 'Gotham', 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-headings: 'Gotham', 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-narrow: 'GothamNarrow', 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;

$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`
$font-size-xs:  ($font-size-base * 1.125);
$font-size-sm:  ($font-size-base * 1.25);
$font-size-lg:  ($font-size-base * 1.5);
$font-size-xl:  ($font-size-base * 1.75);
$font-size-xxl: ($font-size-base * 2);

$font-weight-thin:   100; // Available for Gotham
$font-weight-xlight: 200; // Available for GothamNarrow
$font-weight-light:  300; // Available for Gotham, GothamNarrow
$font-weight-normal: 400; // Available for Gotham, GothamNarrow
$font-weight-medium: 500; // Available for Gotham, GothamNarrow
$font-weight-bold:   700; // Available for Gotham, GothamNarrow
$font-weight-black:  900; // Available for Gotham, GothamNarrow
$font-weight-ultra: 1000; // Available for Gotham, GothamNarrow

$line-height-base: 1.35;
$line-height-sm:   1.15;
$line-height-lg:   1.5;

/**
 * Headings
 */
$h1-font-size: 1.75em;
$h2-font-size: 1.625em;
$h3-font-size: 1.5em;
$h4-font-size: 1.375em;
$h5-font-size: 1.25em;
$h6-font-size: 1.125em;

$headings-margin-bottom: 0.5em;
$headings-font-family:   $font-family-headings;
$headings-font-weight:   $font-weight-black;
$headings-line-height:   $line-height-sm;
$headings-color:         color(--primary);

/**
 * Links
 */
$link-color:            color(--primary);
$link-decoration:       none;
$link-hover-color:      color(--secondary);
$link-hover-decoration: none;

/**
 * Body
 */
$body-bg:    color(--bg);
$body-color: color(--primary);

/**
 * Custom variables
 */
$variables: (
  --header-height: 4.25em,
  --menu-padding: 2.15vw,
  --container-padding-lg: 2.15vw,
  --container-padding: 4.61667vw,
);

@if $css-vars {
  :root {
    @each $variable, $value in $variables {
      #{$variable}: #{$value};
    }
  }
}

/**
 * Responsive variables
 */
@if $css-vars {
  @include media-breakpoint-up(sm) {
    $variables: (
      --header-height: 5.75em,
      --menu-padding: 3.225vw,
      --container-padding-lg: 3.225vw,
      --container-padding: 6.925vw
    );

    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @include media-breakpoint-up(lg) {
    $variables: (
      --menu-padding: 4.8375vw,
      --container-padding-lg: 6.45vw,
      --container-padding: 9.2333vw
    );

    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    $variables: (
      --menu-padding: 6.45vw,
      --container-padding: 13.85vw
    );

    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }
}
