.date-filter {
  .filter {
    &-overlay {
      padding: 1px;

      .button.close-button {
        z-index: 2;
        transform: translate(0, 0);
      }
    }

    &-content {
      max-height: min-content;
      width: 340px;
      height: 340px;
      overflow: visible;

      @include media-breakpoint-up(xs) {
        width: 350px;
        height: 350px;
      }

      @include media-breakpoint-up(md) {
        height: 18.2em;
      }
    }
  }

  .react-daterange-picker {
    display: block;

    &__wrapper {
      @include visually-hidden;
    }

    &__inputGroup {
      &__input {
        color: currentcolor;
      }
    }

    &__calendar {
      // max-width: calc(100% - var(--container-padding-lg) * 2);
      width: 100%;
      max-width: 100%;

      &[style] {
        inset: auto !important;
      }
    }
  }

  .react-calendar {
    border: none;
    font-family: $font-family-narrow;

    &__navigation {
      max-width: calc(100% - 2em);
      margin-bottom: 0;

      &__label,
      &__arrow {
        color: currentcolor;
      }

      &__label {
        text-transform: capitalize;
      }

      button {
        transition: color 0.25s ease;

        &[disabled] {
          background: none;
          opacity: 0.5;
        }

        &:enabled:hover,
        &:enabled:focus {
          background: none;
          color: color(--secondary);
        }
      }
    }

    &__month-view {
      &__weekdays {
        @include visually-hidden;

        // color: color(--secondary);
        // font-weight: $font-weight-medium;
        // text-transform: none;
      }

      &__days {
        &__day {
          color: currentcolor;

          &--neighboringMonth {
            opacity: 0.5;
          }

          &--weekend {
            color: color(--red);
          }
        }
      }
    }

    &__tile {
      transition: background-color 0.25s ease;

      &--now {
        background: none;
        color: color(--secondary) !important;
        font-weight: $font-weight-medium;

        /* stylelint-disable selector-class-pattern */
        &.react-calendar__tile--hasActive:not(:hover),
        &.react-calendar__tile--hasActive:not(:focus) {
          background: none;
        }
        /* stylelint-enble selector-class-pattern */
      }

      &--active,
      &--hasActive,
      &--hover,
      &--hoverStart,
      &--hoverEnd,
      &[disabled] {
        background: rgba(color(--primary-r), color(--primary-g), color(--primary-b), 0.25);
      }

      &[disabled] {
        opacity: 0.15;
      }

      &:enabled:hover,
      &:enabled:focus {
        background: rgba(color(--primary-r), color(--primary-g), color(--primary-b), 0.35);
      }
    }
  }

  .l-dark & {
    .react-calendar {
      background: color(--primary);

      &__tile {
        &--active,
        &--hasActive,
        &--hover,
        &--hoverStart,
        &--hoverEnd,
        &[disabled] {
          background: rgba(color(--black-r), color(--black-g), color(--black-b), 0.5);
        }

        &:enabled:hover,
        &:enabled:focus {
          background: rgba(color(--white-r), color(--white-g), color(--white-b), 0.35);
        }
      }
    }
  }
}
