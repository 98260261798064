/**
 * Visually hidden content
 *
 * @see https://getbootstrap.com/docs/5.0/getting-started/accessibility/#visually-hidden-content
 */
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/**
 * Use mixins and not placeholders (%) because placeholders can't be injected within media queries
 *
 * SassError: You may not @extend an outer selector from within @media.
 */
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin visually-hidden-focusable {
  &:not(:focus):not(:focus-within) {
    @include visually-hidden;
  }
}

@mixin visually-visible {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: unset;
  white-space: normal;
  border: none;
}

/**
 * Reduced motion
 *
 * @see https://getbootstrap.com/docs/5.0/getting-started/accessibility/#reduced-motion
 */
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
