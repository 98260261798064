.template-trainings {
  --current-color: var(--secondary);

  .content {
    padding: 4em 0;
    border: none;
  }
}

.template[class*='template-trainings-'] {
  .section.flexible {
    .flexible-content {
      .text {
        &.l-background {
          &::after {
            opacity: 0.17;
          }
        }
      }

      .text .text-title {
        border-color: var(--current-color);
      }

      .text:not(.text-image):not(.l-inline-title) .text-title {
        padding-left: 0;
        border-left: none;
      }
    }
  }
}
