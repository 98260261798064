/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: 0.5em auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: 0.5em;
  height: auto;
}

.alignleft.size-thumbnail {
  max-width: 30%;
  float: left;
  margin-right: 0.5em;
}

.alignright.size-thumbnail {
  max-width: 30%;
  float: right;
  margin-left: 0.5em;
}

@include media-breakpoint-up(sm) {
  .alignleft {
    float: left;
    margin-right: 0.5em;
  }

  .alignright {
    float: right;
    margin-left: 0.5em;
  }
}
